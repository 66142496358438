#strategies {
	border-bottom: 1px solid $platinum;
	position: relative;
	img {
		max-width: 100%;
	}

	.col-lg-10 {
		li p {
			margin-bottom: 0px;
		}
	}

	header.container-fluid {
		@include single-row-header;
		@include banner;
		@include banner-verdigris;
	}

	.related-strategies {
		padding: $space-md $space-lg;
		padding-right: 48px;
		background-color: rgba(0, 0, 0, 0.2);
		border-bottom: 1px solid $taupe-gray;
		position: relative;
		width: 100%;
		z-index: 1;
		opacity: 0;
		transform: translateY(-92%);
		transition: all .5s ease-in-out;
		&.is-sticky {
			top: 48px;
			position: fixed;
		}
		.nav-carousel {
			position: relative;
		}
		.slick-arrow {
			cursor: pointer;
			position: absolute;
			right: -24px;
			font-size: 16px;
			line-height: 16px;
			text-align: center;
			width: 16px;
			height: 16px;
			color: $white;
			background-color: $mantis;
			border-radius: 4px;
			&.arrow-left {
				top: 24px;
				transform: scale(2);
			}
			&.arrow-right {
				bottom: 20px;
				transform: scale(2);
			}
		}
		.strategy {
			display: flex;
			height: 96px;
			padding: $space-sm;
			border-radius: 4px;
			margin: $space-sm 0;
			box-shadow: 0 4px 0px 0px rgba(0,0,0,.17);

			background-color: $white;
			&:not(:last-of-type) {
				margin-right: $space-md;
			}
			h4 {
				font-size: 16px;
			}
		}
		.strategy__image {
			width: 45%;
			display: flex;
			align-items: center;
			img {
				width: 100%;
				max-height: 96px;
			}
		}
		.strategy__text {
			width: 90%;
			position: relative;
			display: flex;
			align-items: center;
			h4 {
				width: 85%;
				margin: auto;
				font-weight: 100;
			}
			p {
				position: absolute;
				line-height: 20px;
				bottom: -15px;
				left: 0;
				right: 0;
			}
		}
		&.reveal{
			transform: translateY(0%);
		}
	}

	.nav-label {
		position: absolute;
		top: 100%;
		left: $space-lg;
		font-size: 15px;
		border-bottom: 1px solid $taupe-gray;
		border-left: 1px solid $taupe-gray;
		border-right: 1px solid $taupe-gray;
		padding: $space-xs $space-md;
		border-bottom-right-radius: 4px;
		border-bottom-left-radius: 4px;
		background-color: $white;
	}

	.organization {
		p {
			color: $electric-blue;
			text-transform: uppercase;
			font-size: 18px;
		}
	}

	.featured-artifact,
	.nonfeatured-artifact {
		background-color: $light-gray;
		margin: 0;
	}

	.nonfeatured-artifact {
		padding: 20px;
	}

	.featured-artifact {
		a {
			display: block;
			padding: 15px 10px;
		}
	}

	.additional-resource {
		margin-bottom: 20px;
		width: 100%;

		.artifacts {
			border-bottom: 6px solid transparent !important;
		}

		.artifact-content{
			margin-bottom: $space-lg;
			padding: 24px 12px;
			background-color: #EDF2F6;

			h5{
				font-size: 1.6rem;
				color: $true-blue;
			}

			@media(min-width: 767px){
				margin-bottom: $space-xl;
			}

			.artifact-preview{
				background-color: transparent;
				border: none;
				min-height: initial;
				audio{
					width: 100%;
				}
				@media(max-width: 767px){
					margin-top: 32px;
				}
			}

			.artifact-link{
				background-color: transparent;
				span{
					font-size: 12px;
					display: block;
					margin-top: 8px;
				}
				.btn{
					background-color: #0083ca;
					color: $white;
				}
			}
		}

		h2 {
			color: $cadmium-orange;
		}

		.sidebar-content {
			padding: 0;
			min-height: 0;

			h3 {
				color: $taupe-gray;
				text-transform: uppercase;
				font-weight: 300;
				margin: 15px;
				margin-left: 20px;
			}

			p {
				font-size: 16px;
			}

			a {
				color: $taupe-gray;
				text-decoration: underline;
			}

			img {
				width: 100%;
			}
		}

		.equality-focus {
			.row {
				background-color: #def3f1;
				padding: 15px 0px 15px;
				border-radius: 5px;
			}
			margin-top: 5px;
			img {
				border-radius: 200px;
				height: 130px;
				width: 130px;
				margin: auto;
				margin-left: 53px;
				display: block;
			}
			h2 {
				font-size: 18px;
				margin-top: 10px;
			}

			&.content-highlights{
				.row{
					@media(min-width: 767px){
						display: flex;
						align-items: center;
					}
				}
			}
		}

		.tags {
			font-size: 30px;

			.label {
				padding: 5px;
				padding-left: 10px;
				padding-right: 10px;
				font-weight: 400;
			}
		}
	}

	border-bottom: 1px solid $platinum;

	img {
		max-width: 100%;
	}

	header.container-fluid {
		@include single-row-header;
		@include banner;
		@include banner-verdigris;
	}

	.organization {
		p {
			color: $electric-blue;
			text-transform: uppercase;
			font-size: 18px;
		}
	}

	.featured-artifact,
	.nonfeatured-artifact {
		background-color: $light-gray;
		margin: 0;
	}

	.nonfeatured-artifact {
		padding: 20px;
	}

	.featured-artifact {
		&:hover{
			text-decoration: none;
			-webkit-transform: scale(1.05);
			-ms-transform: scale(1.05);
			transform: scale(1.05);
			-webkit-transition: all .2s ease-in;
			transition: all .2s ease-in;
			-webkit-box-shadow: 0 6px 0 0 #ef8322, 0 19px 22px -3px rgba(239,131,34,.25);
			box-shadow: 0 6px 0 0 #ef8322, 0 19px 22px -3px rgba(239,131,34,.25);
		}
		a {
			display: block;
			padding: 15px 10px;
		}
	}

	.right-sidebar {
		margin-bottom: 20px;
		width: 100%;

		.artifacts {
			border-bottom: 6px solid $munsell2;
		}

		h2 {
			color: $cadmium-orange;
		}

		.sidebar-content {
			padding: 0;
			min-height: 0;
			h3 {
				color: $taupe-gray;
				text-transform: uppercase;
				font-weight: 300;
				margin: 15px;
				margin-left: 20px;
			}
			p {
				font-size: 16px;
			}
			a {
				color: $taupe-gray;
				text-decoration: underline;
			}
			img {
				width: 100%;
			}
		}
	}

	.tags {
		font-size: 30px;

		.label {
			padding: 5px;
			padding-left: 10px;
			padding-right: 10px;
			font-weight: 400;
			display: block;
			margin-top: 10px;
		}
	}

	.social-bar.is-sticky {
		top: 230px;
	}
	@media (max-width: 767px){
		.related-strategies{
			display: none;
		}
	}
}
