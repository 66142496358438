body.audience{
  a{
    text-decoration: none;
  }
  select::-ms-expand {
    display: none;
  }
  section{
    // padding: 64px 0;
    padding-top: 64px;
    padding-bottom: 64px;
    @media(max-width:767px){
      // padding:32px 0;
      padding-top: 32px;
      padding-bottom: 32px;
    }
    &.banner{
      padding: $space-xl 100px;
      @media(max-width:767px){
        padding-left: 0;
        padding-right: 0;
        padding-top: 32px;
        padding-bottom:32px;
      }
      min-height: 240px;
      position: relative;
      background: linear-gradient(90.61deg, rgba(108, 192, 181, 0.9) 25.19%, rgba(55, 130, 197, 0.9) 93.07%);
      
      h1{
        font-size: 48px;
        line-height: 64px;
        @media(max-width: 767px){
          text-align:center;
          margin-top: 0;
        }
      }

      .bg-image{
        opacity: .1;
        background-size: cover;
        width: 100%;
        top:0;
        left:0;
        bottom:0;
        right: 0;
        margin: auto;
        position: absolute;
        z-index: 1;
      }
      
      .row{
        @media(max-width:767px){
          padding-left: 16px;
          padding-right: 16px;
        }
        position: relative;
        z-index: 5;
        display: flex;
        flex-wrap: wrap;

        .img, .select{
          display: flex;
          align-items: center;
          justify-content: center;
          img{
            width: 123px;
            margin: auto;
          }
        }

        .select{
          // div{
          //   position: relative;
          //   margin: 32px 0;

            i{
              position: absolute;
              right: -120px;
              top: 12px;
              color: #6F6F6F;
              z-index: 10;

              &.fa-caret-down{
                top: 20px
              }
            }
          // }
          // select{
          //   min-width: 256px;
          //   padding: 8px 12px;
          //   font-size: 16px;
          //   color: #6f6f6f;
          //   -webkit-appearance: none;
          //   -moz-appearance: none;
          //   text-indent: 1px;
          //   text-overflow: '';
          // }
        }
      }
    }

    &.audienceGetStarted{
      padding-left: 100px;
      padding-right: 100px;
      @media(max-width:767px){
        padding-left: 32px;
        padding-right: 32px;
      }
      h2{
        color: $electric-blue;
        text-align: center;
        font-size: 36px;
      }
      p{
        text-align: center;
        font-size: 24px;
        margin-bottom: 32px;
      }
      .row{
        h5{
          text-align: center;
          color: $electric-blue;
          margin: 20px 0;
          @media(max-width: 767px){
            font-size: 2rem;
          }
        }
        p{
          text-align: left;
          font-size: 16px;
          color: #2d2d2d;
          margin: 0;
        }
      }
    }

    &.audience-search{
      background:#EDF2F6;
      padding: 16px 0;
      .container{
        display: flex;
        align-items: center;

        @media(max-width: 767px){
          flex-direction: column;
          justify-content: center;
        }
      }
      h3{
        display: inline;
        font-size: 28px;
        color: $electric-blue;
        padding-right: 16px;
      }
      form{
        display: inline;
        @media(max-width:767px){
          display: flex;
          align-items: center;
        }
				input.query-input{
					border: 1.3px solid #00548B;
					box-sizing: border-box;
					border-radius: 11.6px;
					font-size: 16px;
					padding: 4px 16px;
					margin: auto;
					margin-bottom: 16px;
          display: block;
          margin-bottom: 0;
          margin-right: 8px;

					@media(min-width: 992px){
						min-width: 468px;
						display: inline;
					}
				}
				input[type="submit"]{
					background: #00548B;
					border-radius: 30px;
					color: $white;
					padding: 4px 26px;
					width: fit-content;
				}
			}
    }

    &.audience-latest-content{
      padding-left: 100px;
      padding-right: 100px;
      @media(max-width:767px){
        padding-left: 32px;
        padding-right: 32px;
      }

			.col-xs-12{
        margin-bottom: 32px;
        a{
          @include overlay-link;
        }
			}
      
      .tab{
        background-color: $sky-blue;
        color: $white;
        padding: 12px 26px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        font-size: 24px;
        display: block;
        position: relative;
        top: -32px;
        width: fit-content;

        @media(min-width: 767px){
          top:-64px;
        }
      }

      .view-all{
        font-size: 24px;
        line-height: 28px;
        text-align: right;
        text-decoration: underline;
        color: #0083cA;
        display: block;
      }
      
			h5{
				color: $electric-blue;
				font-size: 28px;
				margin-bottom: 32px;
				span{
					color: $taupe-gray;
					@media(min-width: 992px){
						padding-left: $space-lg;
					}
				}

			}

			img{
				margin-bottom:20px;
				min-height: 176px;
			}
			.slick-next,
			.slick-prev{
        color: $taupe-gray;
        @media(max-width: 767px){
          display: none;
        }
			}
    }
    
    &.audience-testimonials{
      padding: 0;
    }
  }
}
