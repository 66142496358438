#see,
#virtual-schools {
	h1 {
		@include page-title;
	}

	h2 {
		font-size: 42px;
		color: $cadmium-orange;
		font-weight: 300;
		margin-bottom: 15px;
	}
	ol{
		li{
			font-size: 1.2rem;
		}
	}

	header.container-fluid {
		padding: 0;
		width: 100%;

		.header-top {
			@include banner;
			@include banner-cadmium-orange;
		}
		.header-bottom {
			min-height: 550px;
		}

		.right-sidebar {
			padding: 15px 20px;

			@media (min-width: 992px) {
				position: absolute;
				top: 0;
				right: 0;
			}

			.sidebar-content {
				padding: 30px;

				.highcharts-series-group {
					transform: translateY(-80px);
				}

				.highcharts-data-labels {
					transform: translate3d(10px, -47px, 0px);
				}

				.highcharts-container {
					height: 200px !important;

					text {
						@include graph-label;
					}
				}

				.legend {
					padding-left: 10px;

					p {
						margin: 0px;
						font-size: 16px;
						color: $taupe-gray;
					}
				}
			}
		}
	}
  .popular-resources{
    margin: $space-lg 0;
  }
	.grid {
		a {
			// &:nth-child(even) {
			// 	#rect {
			// 		display: none;
			// 	}
			// }
			// &:nth-child(odd) {
			// 	#hero {
			// 		display: none;
			// 	}
			// }
		}
	}
	.card {
		height: 385px;
		overflow: hidden;
		.artifact-icon{
			height: 100%;
			width: auto;
			display: block;
			left: 0;
			right: 0;
			margin: auto;
		}
		h1 {
			color: $electric-blue !important;
			font-weight: 500;
			position: relative;
			overflow: hidden;
		}
		p {
			margin-bottom: inherit;
			position: relative;
			overflow: hidden;
			&:last-child {
				color: $gray;
				font-size: 15px;
			}
		}
		.img-container {
			overflow: hidden;
			height: 211px;
			@media(max-width:1200px){
				height: auto;
			}
			width: 100%;
			margin-bottom: 10px;
			position: relative;

			img {
				width: 100%;
			}
		}
		.school-data {
			display: none;
		}
		.card__labels {
			line-height: 24px;
		}
    .virtual-icon{
		text-align: right;
		position: absolute;
		bottom: 8px;
		right: 16px;
      span{
        text-transform: uppercase;
        letter-spacing: .1em;
        color: #00548B;
        font-size: 10px;
      }
      svg{
        width: 36px;
        height: 36px;
        path{
          fill: #00548B;
        }

      }
    }
	}

	#map-render {
		display: none;
		height: 650px;
		.mapboxgl-marker {
			background-image: url('https://s3-us-west-1.amazonaws.com/tla-craft-assets/images/static/33622__1__480.png');
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
			display: block;
			border: none;
			width: 30px;
			height: 30px;
			position: absolute;
			cursor: pointer;
			padding: 0;
			z-index: 0;
			color: $electric-blue;

			&.virtualSchool{
				background-image: url('/assets/images/icon_virtual-school2x.png');
			}
		}

		.mapboxgl-popup-content {
			width: 500px;
			border-top: 5px solid $electric-blue;
			display: flex;
			align-items: center;
			.material-icons {
				cursor: pointer;
				border: 1px solid currentColor;
				border-radius: 50%;
				&:hover {
					background-color: rgba($taupe-gray, 0.2);
				}
				&[data-nav='next'] {
					margin-left: $space-md;
				}
				&[data-nav='prev'] {
					margin-right: $space-md;
				}
			}
			.description {
				p {
					color: $gray;
					font-size: 15px;
				}
			}
			a {
				text-decoration: none;
			}
			h3 {
				@include link-style($electric-blue);
				font-size: 1.5rem;
			}
			h4 {
				font-size: 1rem;
				color: $gray;
			}
		}
	}

	section.main-content {
		margin-top: $space-xl;
	}

	#sideNav {
		background-color: $light-gray;
		max-width: 250px;
		&.is-sticky {
			position: fixed;
			top: $space-xl;
			z-index: 1;
		}
		max-width: 250px;
		padding: $space-md;
		display: flex;
		flex-direction: column;
		text-align: left;
		color: $taupe-gray;
		li.nav__item {
			margin: $space-sm 0;
		}

		a {
			color: inherit;
			padding: 0;
			&.active {
				color: $cadmium-orange;
			}
		}
		.inset {
			padding-left: 0.8em;
			&.active {
				box-shadow: inset 5px 0 0 $cadmium-orange;
			}
		}
	}

	.banner-holder {
		height: 400px;
		width: 100%;
		overflow: hidden;
		img {
			width: 100%;
			height: auto;
		}
	}

	.topic-wrapper {
		margin: 0;

		p {
			font-size: 15px;
			line-height: 25px;
		}

		h3 {
			color: $light-slate-gray;
			font-size: 30px;
			line-height: 36px;
		}
	}

	.breadcrumb {
		@include breadcrumb;
	}
	.schools-grid {
		height: auto !important;
	}

	.audienceMostPopular{
		h2{
			color: 	#00548B;
			font-size: 36px;
			font-weight: 600;
		}

    .card-carousel{
      .card-slide{
        padding: 0 4px;
      }
      h4 + span{
        display: none;
      }
      .slick-arrow{
        position: absolute;
        font-size: 50px;
        top: 40%;
        cursor: pointer;

        &.arrow-left{
          left: -64px;
        }

        &.arrow-right{
          right: -64px;
        }
      }
    }
	}

}

.schools-top-banner {
	position: absolute;
	background-color: $cadmium-orange;
	min-height: 761px;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	img {
		height: 550px;
	}
}

.social-bar {
	position: absolute;
}

.social-bar.is-sticky {
	position: fixed;
	top: 43px;
}

.layout-controls {
  img{
    display: block;
    margin: auto;
  }
	p {
		margin-top: 70px;
		color: $gray;
	}
}

#virtual-schools{
	.header-top{
    padding: 50px 0;
		background: linear-gradient(180deg, #3882C5 9.62%, #42D5C3 108.61%);
    .row{
      align-items: center;
      display: flex;
    }
    span{
      display: block;
      color: $white;
      font-size: 14px;
      letter-spacing: .1em;
      text-transform: uppercase;
    }
    h1{
      font-size: 36px;
      line-height: 53px;
      font-weight: 700;
      margin: 0;
    }
    svg{
      display: block;
      margin: auto;
    }
	}
}

body.seeLanding{
	.grid__item{
		&[style*="opacity: 0"]{
			display: none;
		}
	}
}