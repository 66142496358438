html,
body {
	height: 100%;
	width: 100%;
	color: $taupe-gray;
	background-color: $background-color;
	font-family: $default-font;
	&.no-scroll{
		overflow: hidden;
	}

	&.preload * {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -ms-transition: none !important;
    -o-transition: none !important;
  }

	@keyframes slide {
		from {
			transform: translateX(-100%);
		}
		to {
			transform: translateX(100%);
		}
	}

	.fa-youtube{
		color: #FE0011;
	}	
	
	.placeholder {
		position: absolute;
		margin: auto;
		// padding: 10px 10px 60px;
		width: 100%;
		height: 100%;
		left:0;
		top:0;
		overflow: hidden;
		user-select: none;
		pointer-events: none;

		&:after{
			content: "";
			position: absolute;
			height: 100%;
			width: 100%;
			background-color: #e9e9e9;
			top: 0;
			left: 0;
			z-index: 1;
		}
		
		&::before {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 10;
			background:
				linear-gradient(to right, rgba(255, 255, 255, 0) 30%,
				rgba(255, 255, 255, 0.4) 50%,
				rgba(255, 255, 255, 0) 54%) 50% 50%;
			animation: slide 1s linear infinite;
		}
	}
}
ul,ol {
	font-size: 1.2em;
}
.form-group{
	margin-bottom: 0px;
}
.main-wrapper {
	min-height: 650px;
	overflow-x: hidden;

	table{
		margin: 36px 0;
		th{
		  border-bottom: 2px solid #eceeef;
		}
		td,th{
		  border-top: 1px solid #eceeef;
		  padding: 12px 8px;
		}
	
		thead{
		  &.thead-verdigris{
			th{
			  background-color: $verdigris;
			  color: $white;
			}
		  }
		  &.thead-dark{
			color: #fff;
			background-color: #212529;
		  }
		}
	
		&.table-dark{
		  color: #fff;
		  background-color: #212529;
		}
	  }
}

ol.breadcrumb {
	padding-left: 0;
	z-index: 100;
	// background-color: transparent;
	// li{
	// 	font-size: 16px;
	// 	&:not(:first-child){
	// 		&:before{
	// 			content: ">"
	// 		}
	// 	}
	// }
}

a {
	cursor: hand;
	cursor: pointer;
	&:focus {
		text-decoration: none;
	}
}

*:focus {
	outline: none;
}

.social-buttons {
	a {
		color: white;
		margin-left: 15px;
	}
	display: flex;
	justify-content: flex-end;
}

@include tags;

img {
	max-width: 100%;
}

.sidebar-content {
	@include sidebar-content;
}

.sibling-preview {
	color: $taupe-gray;
	@include preview-md;
	margin-bottom: 20px;

	.preview-content {
		height: auto;

		a {
			color: $verdigris;
			@media (min-width: 34em) {
				font-size: 24px;
				line-height: 26px;
			}
		}

		p {
			@media (min-width: 34em) {
				margin-top: 10px;
				font-size: 18px;
				line-height: 20px;
			}
		}
	}
}

.page-intro {
	@include page-intro;
}

.pagination {
	padding: $space-md 0;
	display: flex;
	justify-content: center;
	text-transform: uppercase;
	font-size: 20px;

	a,
	span {
		margin: auto 10px;
		position: relative;
		color: $verdigris;
		&:hover {
			text-decoration: none;
		}
		&::before,
		&::after {
			top: -3px;
		}
	}

	.page-number {
		color: $taupe-gray;
		transform: translateY(10px);
		position: relative;
		display: block;
		width: 22px;
		height: 16px;
		text-align: center;
		&.current {
			color: $verdigris;
			&::before {
				background-color: currentColor;
			}
		}
		&::before {
			content: '';
			position: absolute;
			left: 0;
			right: 0;
			margin-left: auto;
			margin-right: auto;
			top: -16px;
			width: 16px;
			height: 16px;
			background-color: currentColor;
		}
	}

	.prev-link::before {
		content: '';
		position: absolute;
		left: -25px;
		border-bottom: 15px solid transparent;
		border-top: 15px solid transparent;
		border-right: 15px solid $verdigris;
		font-size: 0;
		line-height: 0;
	}

	.next-link::after {
		content: '';
		position: absolute;
		right: -25px;
		border-bottom: 15px solid transparent;
		border-top: 15px solid transparent;
		border-left: 15px solid $verdigris;
		font-size: 0;
		line-height: 0;
	}
}

.page-content {
	color: $taupe-gray;
	h2 {
		color: $verdigris;
		font-size: 30px;
		font-weight: 300;
	}

	ul,ol {
		font-size: 18px;
		font-weight: 300;
	}

	li {
		padding-bottom: 10px;
	}

	.explore-all {
		color: $verdigris;
		text-transform: uppercase;
	}
}

.body-copy {
	@include grey-bullet-list;

	table{
		margin: 36px 0;
		th{
			border-bottom: 2px solid #eceeef;
		}
		td,th{
			border-top: 1px solid #eceeef;
			padding: 12px 8px;
		}

		thead{
			&.thead-verdigris{
				th{
					background-color: $verdigris;
					color: $white;
				}
			}
			&.thead-dark{
				color: #fff;
				background-color: #212529;
			}
		}

		&.table-dark{
			color: #fff;
			background-color: #212529;
		}
	}
}

.strategy-preview {
	@include preview-lg;
}

#artifacts, .additional-resource{
	.artifact-link {
		width: 100%;
		flex: 3;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background-color: white;
		padding-left: 15px;
		padding-right: 15px;

		.btn {
			text-transform: uppercase;
			width: 100%;
			color: $true-blue;
			border: 2px solid $true-blue;
			border-radius: 5px;
			font-size: 18px;
			white-space: normal;
			// width: fit-content;
			// margin-right: auto;

			&:hover,
			&:focus {
				color: white;
			}
		}
	}
}

.artifact-preview {
	border: 4px solid $light-gray;
	border-radius: 4px;
	background-color: $light-gray;
	display: flex;

	.preview-icon {
		width: 100%;
		flex: 1.5;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		img {
			@extend .circle;
			display: block;
			width: 60%;
			max-width: 150px;
			margin: 5%;
		}
	}

	@media (min-width: 1023px) {
		flex-direction: column;
		width: 100%;
		min-height: 350px;

		.preview-icon {
			flex: 4;
		}
	}
}

.featured-artifact {
	width: 100%;

	.artifact-preview {
		width: 100%;
		min-height: 300px;
		background-color: white;
	}
}

.nonfeatured-artifact {
	@include preview-sm;
}

//embed responsively
.embed-container {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;
	max-width: 100%;

	iframe,
	object,
	embed {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

.error-page {
	color: white;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	min-height: 800px;
	background-color: #0084cc;
	h1 {
		font-size: 50px;
	}
	h3 {
		font-size: 30px;
		line-height: 50px;
		font-weight: normal;
	}
	a{
		color: white;
	}
}

@media only print {
	nav,
	.right-sidebar,
	.explore-more,
	footer,
	.social-buttons,
	.breadcrumb {
		display: none !important;
		height: 0 !important;
	}
}

#instruction-banner, .bannerMessage {
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 0;
	background: #42382f;
	z-index: 999999;
	display: none;
	align-items: center;
	justify-content: center;

	p, div {
		margin: 0;
		color: white;
		position: relative;
		width: 100%;
		text-align: center;
		a {
			color: white;
			text-decoration: none;
			.fa {
				color: #dc4537;
				margin-left: 5px;
			}
		}
		.btn-close {
			color: white;
			position: absolute;
			top: 5px;
			right: 20px;
			z-index: 999;
			cursor: hand;
			cursor: pointer;
		}
	}
}

.bannerMessage{
	background: #595959;
	position: relative;
}

.gdpr{
	display: none;
	position: fixed;
	bottom: 0;
	background-color: #413830;
	z-index: 1020;
	width: 100%;
	left: 0;
	right: 0;
	.btn-close{
		border-radius: 100%;
		padding: 5px;
		position: absolute;
		right: 15px;
		top: 15px;
		transform: scale(1.5);
		cursor: pointer;
	}
	.container{
		padding: $space-lg 0;
		p{
			color: white;
			margin: 0;

			@media(max-width:769px){
				margin: 0 20px;
			}
		}
		a{
			font-weight: 700;
			color: white;
			text-decoration: none;
		}
	}
}

.modal-dialog {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	margin: auto;
	height: 50%;
}

.modal-body {
	background-color: $electric-blue;
}

.modal-backdrop {
	background-color: $electric-blue;
	opacity: 0.7 !important;
}

header {
	.btn-group {
		.fa {
			color: white;
		}
	}
	#mobile-social-buttons {
		border-radius: 4px;
		padding: 5px;
		display: none;
		width: 300px;
		background-color: rgba(0, 0, 0, 0.3);
		border: 1.5px solid white;
		justify-content: space-around;
		margin-bottom: 20px;
	}
}

.error {
	display: none;
	background-color: #f2dede;
	border-color: #eed3d7;
	color: #b94a48;
	margin: 5px 0;
	border-radius: 4px 4px 4px 4px;
	padding: 8px 35px 8px 14px;
	text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
	ul {
		padding: 0px 15px;
		margin: 5px 0;
	}
}

#thanks {
	visibility: hidden;
}
.response-error {
	display: none;
	background-color: #f2dede;
	border-color: #eed3d7;
	color: #b94a48;
	margin: 5px 0;
	border-radius: 4px 4px 4px 4px;
	padding: 8px 35px 8px 14px;
	text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
}
.response-success {
	display: none;
	background-color: #89d676;
	border-color: #89d676;
	color: #2ca725;
	margin: 5px 0;
	border-radius: 4px 4px 4px 4px;
	padding: 8px 35px 8px 14px;
	text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
}

.social-bar {
	position: fixed;
	background-color: #ececec;
	-webkit-border-radius: 4px;
	border-radius: 4px;
	right: 0;
	padding: 20px 0;
	z-index: 3;
	.social-buttons {
		display: flex;
		flex-direction: column;
	}
	a {
		color: $gray;
		margin-right: 5px;
		margin-bottom: 10px;
		i {
			font-size: 2.5em;
		}
		&:hover {
			color: $electric-blue;
		}
	}
}

.lds-ring {
  display: block;
  position: relative;
  width: 200px;
	height: 200px;
	margin: auto;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 150px;
  height: 150px;
  margin: 6px;
  border: 12px solid $gray;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: $gray transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.embed-code{
	position: relative;
	// width: 100%;
	height: 0;
	// padding-bottom: 57%;
	margin-top: $space-lg;
	margin-bottom: $space-lg;
	iframe{
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0; top: 0;
		border: none;
	}
}

// slick arrows
.slick-next,
.slick-prev{
	background: none;
	border: none;
	position: absolute;
	top: 40%;
	color: $white;
	transform:scale(2);
	z-index: 99;
}

.slick-next{
	right: -50px;
}
.slick-prev{
	left: -50px;
}

.new-flag-wrapper{
	overflow: hidden;
	position: absolute;
	top: 0px;
	right: 3px;
	height: 200px;
	width: 200px;
	&:after{
		content: "";
		position: absolute;
		top:0;
		right:0;
		width: 100%;
		height: 3px;
		background-color: white;
	}

	&:before{
		content: "";
		position: absolute;
		top:0;
		right:0;
		width: 3px;
		height: 100%;
		background-color: white;
	}
	
	.new-flag{
		background-color: $cadmium-orange;
		color: #fff;
		font-size: 23px;
		transform: rotate(45deg);
		padding: 45px 65px 8px;
		position: relative;
		top: -19px;
    right: -70px;
	}
}


.signup-block{
	background: #42B5A8;
	padding: 54px 0;

    p{
      color: $white;
    }
	
  form{
    input[type="text"]{
      width: 60%;
      border: 1.3px solid #00548B;
      border-radius: 5.6px;
      min-height: 40px;
      padding: 8px;
    }
    input[type="submit"]{
      width: 114px;
      color: $white;
      background-color: #00548B;
      border-radius: 5px;
      min-height: 40px;
      vertical-align: top;
    }
  }
}