.guides {
  .main-wrapper{
    overflow-x: hidden;
  }
  section {
    &.banner {
      background-size: cover;
      // background-image:url('/assets/images/_00_guide-hero_01_rtr@2x.jpg');
      background: radial-gradient(54.17% 107.99% at 47.78% 80.86%, #6CC0B5 0%, #3782C5 96.5%);
      min-height: 300px;
      text-align: center;
      display: flex;
      align-items: center;
      color:  $white;
      position: relative;

      .container{
        position: relative;
      }

      img{
        width: 120px;
        position: absolute;
        top:0px;
        left: -148px;
      }
      h1{
        font-size: 56px;
        line-height: 64px;
        font-weight: 700;
        color:  $white;
      }
      p{
        font-size: 28px;
      }
    }

    &.guide {
      display: flex;
      justify-content: center;
      cursor: pointer;
      position: relative;
      margin-top: 32px;
      margin-bottom: 32px;

      @media(min-width:767px){
        margin-top: -32px;
        margin-bottom: 120px;

      }


      &>.card-guide {
        // background-color: $white;
        // overflow: hidden;
        // padding: 32px 64px;
        // border: 3px solid #0083CA;
        // z-index: 10;

        @media(min-width:992px){
          min-width: 1119px;
        }

        h2{
          text-align: left;
        }

        p{
          color: $taupe-gray;
          text-align: left;
        }

        img{
          width: 100%;
        }

        .img{
          background-size: contain;
          background-position: center;
          width: 100%;
          height: 300px;
          background-repeat: no-repeat;
        }

        ul {
          list-style: none;
          li{
            margin-bottom: 12px;
            text-align: left;
            a{
              font-weight: 700;
              text-decoration: none;
              color: #0083CA;
            }
          }
        }
      }

      h2 {
        color: $electric-blue;
        font-weight: 700;
      }

      .row{
        margin-top: 32px;
      }
    }

    &.coming-soon {
      background-color: $munsell;
      padding: 2*$space-xl 0;
      margin-bottom: 0;
      border-bottom: 1px solid #42B5A8;

      @media(min-width:767px) {
        margin-top: -80px;
        padding-top: 100px;
      }

      h2 {
        text-align: center;
        font-size: 46px;
        color: $electric-blue;
        margin-bottom: 36px;
      }

      .row{
        @media(min-width: 992px){
          display: flex;
          justify-content: center;
        }
      }

      .container {

        &>h4 {
          color: $electric-blue;
          font-size: 28px;

          span {
            padding: 0;
            color: $copyBlack;
            font-weight: 300;

            @media(min-width: 992px) {
              padding-left: $space-lg;
            }
          }

          margin-top: 64px;
          margin-bottom: 64px;
          position: relative;

          div {
            display: inline;
          }

          span {
            padding: 0;
            color: $copyBlack;
            font-weight: 300;
          }

          // @media(min-width: 992px) {
          //   margin-left: 10px;
          //   padding-left: 186px;

          //   svg {
          //     position: absolute;
          //     left: 0;
          //   }

          //   div {
          //     display: none;
          //   }

          //   &:before {
          //     content: "Guides: ";
          //     position: absolute;
          //     left: 50px;
          //   }
          // }
        }

        form {
          text-align: center;

          input.query-input {
            border: 1.3px solid #00548B;
            box-sizing: border-box;
            border-radius: 5.6px;
            font-size: 16px;
            padding: 4px 16px;
            margin: auto;
            margin-bottom: 16px;
            display: block;

            @media(min-width: 992px) {
              min-width: 468px;
              display: inline;
            }
          }

          input[type="submit"] {
            background: #2F8076;
            border-radius: 5.6px;
            color: $white;
            padding: 4px 26px;
            width: fit-content;
          }
        }

        .coming-soon__card {
          box-shadow: 0px 13px 21px -18px rgba(54, 153, 191, 0.18);

          h4 {
            font-size: 20px;
          }

          h3 {
            font-size: 28px;
            color: #2F8076;
            margin-bottom: 32px;
          }

          p {
            font-size: 17px;
          }

          border: 4px dashed #42B5A8;
          background-color: $white;
          padding: 28px 27px;

          .img {
            min-height: 177px;
            background-color: rgba(66, 181, 168, .3);
            display: flex;
            align-items: center;
            justify-content: center;
            svg{
              width: 200px;
            }
          }

          @media(max-width:767px){
            margin-bottom: 16px;
          }
        }
      }
    }
  }
}

.guideTab{
  .main-wrapper{
    overflow-x: hidden;
  }
  section{
    &.banner{
      position: relative;
      overflow:hidden;
      transition: min-height 0.25s ease-out; 
      .row{
        @media(min-width: 767px){
          display: flex;
          align-items: center;
        }
      }
      h1{
        font-weight: 700;
        transition: all .25s ease-in-out;
        margin-top: 0;
        margin-bottom: 0;
        color:  #002E55;
        @media(max-width:767px){
          text-align: left;
        }
      }
      font-weight: 400;
      background-size: cover;
      background-image:url('/assets/images/RTR_background-flip.jpg');
      // background: radial-gradient(54.17% 107.99% at 47.78% 80.86%, #6CC0B5 0%, #3782C5 96.5%);
      color:  #002E55;
      min-height: 444px;
      display: flex;
      align-items: center;
      a{
        color:  #002E55;
      }
      p{
        font-size: 28px;
        line-height: 36px;
        transition: all .25s ease-in-out;
      }
      img{
        width: 100%;
      }

      .container{
        @media(min-width: 1200px){
          min-width: 1140px;
        }
        @media(min-width:1500px){
          &.wide{
            max-width: initial;
            padding: 0 166px;
          }
        }

        .col-md-6{
          &:last-child{
            display: flex;
            align-items: center;
          }
        }

      }

      img{
        mix-blend-mode: multiply;
        @media(min-width: 767px){
          // position: absolute;
          // bottom:20px;
          // right:100px;
          z-index: 5;
          max-width: 265px;
          text-align: right;
          margin-left: auto;
          display: block;
        }
      }

      &.shrink{
        min-height: 140px;
        h1{
          font-size: 32px;
        }

        p, img{
          display: none;
        }

        span{
          font-size: 12px;
        }
      }

      &.is-sticky{
        position: fixed;
        top: 48px;
        width: 100%;
        z-index: 9;
        transition: min-height 0.25s ease-in;
      }

      @media(min-width: 992px){
        .container{
          // padding: 0 40px;
          // max-width: initial;
          // min-width: initial !important;
          width: 100%;
        }

      }

      @media(max-width:992px){
        .container{
          max-width: initial;
          width: 100%;

          .col-xs-12{
            z-index: 10;
          }

          img{
            width: 70%;
            // display: block;
            margin: auto;
          }
        }
      }
    }

    &.guide-tab{
      transition: all .25s ease-in-out;
      position: relative;
      &.is-sticky{
        position: fixed;
        width: 100%;
        top: 48px;
        z-index: 99;

        & + section{
          padding-top: 68px;
        }
      }
      button{
        position: absolute;
        right: 16px;
        top: -6px;
        &:focus{
          outline:0;
        }
      }
      ul{
        background-color: white;
        padding: 0 64px;
        list-style: none;
        display: flex;
        justify-content: space-between;
        min-height: 42px;
        box-shadow: 0px 2px 0px 5px rgba(0, 0, 0, 0.05);
        margin: 0;
        margin-bottom: 32px;
        transition: height .25s ease-in-out;
        @media(min-width: 1200px){
          padding: 0 166px;
        }
        li{
          position: relative;
          padding-top: 5px;
          font-size: 16px;
          &:after{
            content:"";
            bottom:0;
            left:0;
            width: 100%;
            background-color: $mantis;
            height: 5px;
            position: absolute;
            opacity: 0;
            transition: all .25s ease-in-out;
          }
          &:hover{
            &:after{
              opacity: 1;
            }
          }
          a{
            color: $electric-blue;
            font-weight: 400;
            transition: all .25s ease-in-out;
            padding: 6px 22px;
            text-decoration: none;
            display: block;
            line-height: 18px;
          }
          &.active{
            a{
              font-weight: 700;
            }
            &:after{
              opacity: 1;
            }
          }
        }


        @media(max-width:1025px){
          padding: 0 16px;
          padding-bottom: 16px;
          display: block;
          height: 0px;
          overflow-y: hidden;
          li{
            a{
              padding: 0;
            }
            &:first-child{
              padding-top: 48px;
            }
            &:after{
              content: none;
            }
          }
        }
      }

      .guide-hamburger{
        display: none;

        @media(max-width: 1025px){
          display: block;
        }
      }
    }

    &.guide-content{
      .contentCallout{
        float: none;
      }
      @media(min-width: 1500px){
        .container{
          &.wide{
            max-width: initial;
            padding: 0 166px;
          }
        }
      }
      .intro{
        margin-bottom: 32px;
        p.subtitle{
          font-size: 1.8rem;
        }

        .col-md-3{
          .images{
            img{
              @media(min-width:1200px){
                position: absolute;
                top:0;
                left: 16px;
                width: 20vw;
                max-width: initial;
              }
            }
          }
        }
      }

      .sideNav{
        background-color: #F0F9FF;
        padding: 16px;

        h4{
          color: $electric-blue;
          font-weight: 600;
          font-size: 14px;
        }

        ul{
          list-style: none;
          padding: 0;
          li{
            a{
              font-size: 16px;
              color: #575959;
              text-decoration: none;
              &:hover{
                color: $electric-blue;
              }
              &.active{
                color: $electric-blue;
                font-weight: 700;
              }
              svg{
                margin-right: 8px;
              }
            }
          }
        }
        @media(max-width:767px){
          margin-bottom: 16px;
        }
        @media(min-width:992px){
          &.is-sticky{
            position: fixed;
            top: 120px;
            z-index: 8;
            align-items: center;
            width: 255px;
            max-width: 255px;
        
            .navbar-brand img {
              width: $space-lg;
              height: $space-lg;
            }
        
            li.nav-item.submenu-toggle {
              display: flex;
              align-items: center;
              justify-content: center;
        
              form.form-inline.search-form {
                margin: 0;
              }
        
              a.nav-link {
        
                .nav-link__icon,
                .nav-link__text p {
                  display: none;
                }
              }
            }
          }
        }
      }

      h2{
        color: $electric-blue;
        font-weight: 700;
      }

      h3{
        font-size: 22px;
        color: $electric-blue;
        font-weight: 600;
        display: inline-block;
      }

      .images{
        padding-top:64px;
      }

      //blocks
      .subNavHeader{
        margin-top: 16px;
      }

      .text{
        li{
          font-size: 1rem;
        }
      }

      .mixedResources{
        padding: 0;
        margin-bottom: 16px;
        a[target="_blank"]{
          @include link-overlay;
          i{
            display: none;
          }

          &:hover{
            & + .card{
              box-shadow: 0px 8.9391px 8.9391px rgba(0, 0, 0, 0.1);

              .card__content{
                background: #E2F3FF;
              }
            }
          }
        }
        
        h5{
          color: $electric-blue;
          font-weight: 600;
          font-size: 22px;
        }

        .slick-list{
          padding: 16px 0;
        }

        button{
          color: $electric-blue;
          background-color:  #F7F9FA;
          border-radius: 100%;
          width: 25px;
          height: 25px;
        }

        .card{
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
          transition: all .6s ease-in-out;

          .card__image{
            background: linear-gradient(180deg, #3882C5 9.62%, #42D5C3 108.61%);

            .artifact-icon{
              margin: auto;
              left: 0;
              right: 0;
              top: 0;
              bottom: 0;
            }
          }

          .card__content{
            min-height: 190px;
            background: $white;
            transition: all .6s ease-in-out;

            .card__title{
              border:none;
              color: $electric-blue;
            }

            span{
              text-transform: uppercase;
              color: #575959;
              font-size: 12.51px;
              line-height: 23.24px;
            }
          }

          &:hover{
            box-shadow: 0px 8.9391px 8.9391px rgba(0, 0, 0, 0.1);

            .card__content{
              background: #E2F3FF;
            }
          }
        }
      }

      .video{
        .aspect-ratio{
          position: relative;
          width: 83%;
          height: 0;
          padding-bottom: 46%;
          margin-bottom: $space-lg;
          iframe{
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0; top: 0;
            border: none;
          }

          @media(max-width: 767px){
            width: 100%;
            height: 60%;
          }
        }
      }

      .blockQuote{
        background-color: #E2F3FF;
        margin-bottom: 48px;
        margin-top: 16px;

        padding: 32px 16px;
        padding-left: 32px;

        @media(min-width:767px){
          padding: 64px 111px;

        }
        blockquote{
          position: relative;
          margin-bottom: 16px;
          @media(min-width:767px){
            margin-bottom: 32px;
          }

          span{
            font-size: 24px;
            display: inline-block;
            padding-left: 10px;

            // @media(min-width: 767px){
            //   font-size: 24px;
            // }
          }
          &:before{
            content:"“";
            position: absolute;
            color: #0083CA;
            top: -40px;
            left: -30px;
            font-size: 60px;
            @media(min-width:767px){
              left: -70px;
              font-size: 111px;
            }
          }
          p{
            color: #0083CA;
            font-size: 1.2rem;
            display: inline;
          }

        }
        div{
          text-transform: uppercase;
          color: #0083CA;
          font-size: 14px;
          font-weight: 700;
        }
        span{
          text-transform: uppercase;
          letter-spacing: 1px;
          font-size: 14px;
          color: #0083CA;
        }
      }

      .center-btn{
        display: block;
        justify-content: center;
        margin-top: 16px;
        margin-bottom: 32px;
        .cta{
          .btn{
            margin-top: 0;
            margin-bottom: 0;
          }
          margin-top: 0;
          margin-bottom: 0;

          @media(max-width: 767px){
            margin-bottom: 16px;
            margin-left: 0 !important;

            .btn{
              margin-left: 0;
            }
          }
        }

        @media(min-width: 992px){
          display: flex;
        }
      }

      .cta{
        margin-top: 16px;
        margin-bottom: 50px;
        display: inline-block;
        & + .cta{
          display: inline-block;
          margin: 0 16px;
        }
        .btn{
          background-color: #0083CA;
          color: $white;
          border-radius: 4px;
          font-size: 18px;
          line-height: 24px;
          font-weight: 400;
          max-width: 300px;
          padding: 13px 19px;
          white-space: pre-wrap;
            word-wrap: break-word;
            word-break: break-all;
            white-space: normal;
            display:block;
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
          i{
            display: none !important;
          }
        }
      }

      .artifactsResources{
        margin-top:16px;

        a{
          color: inherit;
          text-decoration: none;
        }

        a[target="_blank"]{
          @include link-overlay;

          &:hover{
            & + .card{
              box-shadow: 0px 8.9391px 8.9391px rgba(0, 0, 0, 0.1);

              .card__content{
                background: #E2F3FF;
              }
            }
          }
        }

        h3{
          color: #00548B;
          margin-bottom: 32px;
        }

        h4{
          color: $electric-blue;
          font-size: 20px;
        }

        .item{
          margin-bottom: 16px;
          min-height: 190px;

          img{
            @media(max-width:767px){
              width: 100px;
              margin:auto;
              margin-bottom: 16px;
              display: block;
            }
          }
        }
      }
    }

    &.brainstorm{
      background-color: #ECF3F7;
      min-height: 243px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 32px;
      border-bottom: 1px solid #42B5A8;

      .container{
        @media(min-width: 1200px){
          min-width: 1140px;
        }

        .col-md-4{
          @media(max-width:767px){
            margin-bottom: 16px;
          }
          &:first-child{
            p,i{
              color: #00548B;;
            }
            p{
              font-size: 12px;
              margin-bottom: 24px;
            }

            a{
              font-weight: 700;
              color: #00548B;
              position: relative;
              text-decoration: none;
              i{
                margin-right: 8px;
              }
              @media(min-width:767px){
                i{
                  position: absolute;
                  left: -24px;
                  top: 2px;
                }
              }

            }
            svg{
              transform:rotate(180deg)
            }
          }

          &:last-child{
            span{
              display: inline-block;
              padding-left: 16px;
              color: $electric-blue;
              font-weight: 600;
              font-size: 14px;
              line-height: 30px;
              letter-spacing: 0.1em;
            }
    
            a{
              background-color: $true-blue;
              padding: 13px 19px;
              color: $white;
              border: 2px solid #0083CA;
              box-sizing: border-box;
              box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
              border-radius: 4px;
              display: block;
              text-decoration: none;
    
              svg{
                float: right;
                padding-top: 5px;
                path{
                  fill: $white;
                }
              }
            }
          }
        }
        
      }
    }
  }

}