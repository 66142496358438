.contentCallout{
    margin-bottom: 32px;
    margin-top: 16px;
    padding:0;

    & + .contentCallout{
      margin-top: 0;
    }
  
    h2,h3{
      color: $electric-blue;
    }

    h3{
        font-size: 22px;
        color: #00548b;
        font-weight: 600;
        display: inline-block;
    }

    p{
      color: $electric-blue;
    }

    &.background{
      background-color: #E2F3FF;
      padding: 32px;
    }
  }

.associatedResources{
  margin-bottom: $space-lg;
  .card{
    margin-bottom: 32px;
  }
}