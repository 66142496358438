.snippets{
  .testimonials{
    overflow-x: hidden;
    padding: 64px 0;
    background: linear-gradient(90.61deg, #6CC0B5 25.19%, #3782C5 93.07%);
    position: relative;

    .tab{
      background-color: $sky-blue;
      color: $white;
      padding: 8px 16px;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      font-size: 24px;
      display: block;
      position: relative;
      top: -64px;
      width: fit-content;
    }

    .testimonials_slider{
      position: relative;
    }

    .testimonial{

      background-color: $white;
      border-radius: 10px;
      padding: 20px;
      margin: 0 10px;
      min-height: 300px;

      @media(min-width: 992px){
        display: flex;
        align-items: center;
      }
      img{
        width: 25%;
        margin: auto;
        @media(max-width: 992px){
          margin-bottom: 32px;
        }
        // height: 100%;
        // background-position: center;
        // background-size: cover;
        // background-repeat: no-repeat;
      }
      .copy{
        color: black;
        
        @media(min-width: 992px){
          width: 60%;
          margin-left: 40px;
          q{
            font-size: 21px;
            margin-bottom: $space-lg;
          }
          span{
            font-size: 18px;
            font-weight: 700;
          }
        }

      }
    }
  }
}