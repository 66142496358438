.arrow-wrap {
  position:absolute;
  z-index:1;
  left:50%;
  top:37em;
  margin-left:-5em;
  background:$electric-blue;
  width:10em;
  height:10em;
  padding:4em 2em;
  border-radius:50%;
  font-size:0.5em;
  display:block;
  box-shadow:0px 0px 5px 0px #333;
  transform: scale(.5);
}

.conditions-for-success-scale .arrow {
  float:left;
  position:relative;
  width: 0px;
height: 0px;
border-style: solid;
border-width: 3em 3em 0 3em;
border-color: #ffffff transparent transparent transparent;
  -webkit-transform:rotate(360deg)
}

.conditions-for-success-scale .arrow:after {
  content:'';
  position:absolute;
  top:-3.2em;
  left:-3em;
  width: 0px;
height: 0px;
border-style: solid;
border-width: 3em 3em 0 3em;
border-color: $electric-blue transparent transparent transparent;
  -webkit-transform:rotate(360deg)
}


.hint {
  position:absolute;
  top:0.6em;
  width:100%;
  left:0;
  font-size:2em;
  font-style:italic;
  text-align:center;
  color:#fff;
  opacity:0;
}


.arrow-wrap:hover .hint {
  opacity:1;
}


  @-webkit-keyframes arrows {
    0% { top:0; }
    10% { top:12%; }
    20% { top:0; }
    30% { top:12%; }
    40% { top:-12%; }
    50% { top:12%; }
    60% { top:0; }
    70% { top:12%; }
    80% { top:-12%; }
    90% { top:12%; }
    100% { top:0; }
  }

  .arrow-wrap .arrow {
    -webkit-animation: arrows 2.8s 0.4s;
    -webkit-animation-delay: 3s;
  }
