#learn {
	.banner {
		margin-bottom: $space-lg;
		h1 {
			margin: 2*$space-xl 0;
		}
	}
	section {
		margin-bottom: $space-lg;
		color: $gray;
	}
	.menu {
		@include list-no-style();
		background-color: #eff8fa;
		border-bottom: 3px solid #e1e9eb;
		li {
			margin: 0px 20px 0px 20px;
			border-bottom: 1px solid #cbe2e6;
			padding: 10px 0px 10px;
			font-size: 16px;
			color: #46525a;
			cursor: pointer;
			&:last-child {
				border-bottom: 0px;
			}
		}
	}
	.copy {
		.aspect-ratio{
			position: relative;
			width: 100%;
			height: 0;
			padding-bottom: 56%;
			
			iframe{
				position: absolute;
				width: 100%;
				height: 100%;
				left: 0; top: 0;
				border: none;
			}
		}
	}
	.learnDoSectionCta {
		border-bottom: 1px solid #cbe2e6;
		a {
			margin-top: 10px;
			margin-bottom: 10px;
			border-radius: 3px;
			cursor: pointer;
			display: inline-block;
			background-color: $electric-blue;
			color: white;
			padding: 23px 29px 23px 29px;
			box-shadow: 0px 4px 0px 0px rgba(42, 136, 173, 1);
		}
		img {
			width: 74px;
			height: 74px;
		}
	}
	#why-make-the-shift {
		img {
			width: 75%;
		}
	}
	.copyWithImage {
		p{
			a{
				background-color: transparent;
				color: $electric-blue;
				padding: 0;
				box-shadow: none;
				margin: 0;
				// i{
				// 	display: none;
				// }
			}
		}
		a {
			margin-top: 10px;
			margin-bottom: 10px;
			border-radius: 3px;
			cursor: pointer;
			display: inline-block;
			background-color: $electric-blue;
			color: white;
			padding: 15px 29px 15px 29px;
			box-shadow: 0px 4px 0px 0px rgba(42, 136, 173, 1);
			letter-spacing: 1px;
		}
		.copyWithImage__copy {
		}
		.copyWithImage__image {
			display: flex;
			justify-content: center;
		}
	}
	.copyAndVideo {
		.container {
			.aspect-ratio{
        position: relative;
        width: 100%;
        height: 0;
				padding-bottom: 60%;
				
				&.half{
					@media(min-width: 992px){
						width: 50%;
						padding-bottom: 28%;
						float: left;
						margin-right: 32px;
					}
				}

				@media(max-width:992px){
					width: 100%;
					padding-bottom: 65%;
				}
				@media(min-width:992px){
					margin-bottom: 35px;
				}
        iframe{
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0; top: 0;
          border: none;
        }
      }
		}
	}

	.sibling-entries {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: $space-lg 0;
		text-transform: uppercase;
		@media(max-width: 992px){
			display: block;
		}
		a {
			@include link-style($electric-blue);
			font-size: 20px;
			@media (max-width: 560px){
				font-size: 15px;
			}
		}
		.prev-entry-link {
			position: relative;
			padding-right: $space-xl;
			@media(max-width:992px){
				display: inline-block;
			}
			@media(max-width: 560px){
				margin-bottom: 20px;
			}
			&::before {
				@include triangle('right', $electric-blue, 10px);
				position: absolute;
				right: 0;
				top: 0;
				bottom: 0;
				margin: auto;
			}
			@media(max-width: 992px){
				padding-right: 0;
				width: 90%;
				&:before{
					content: none;
				}
				&:after{
					@include triangle('right', $electric-blue, 10px);
					position: absolute;
					right:-30px;
					top: 0;
					bottom: 0;
					margin: auto;
				}
			}
		}
		.next-entry-link {
			position: relative;
			padding-right: $space-xl;
			@media(max-width:992px){
				display: inline-block;
			}
			&::before {
				@include triangle('right', $electric-blue, 10px);
				position: absolute;
				right: 0;
				top: 0;
				bottom: 0;
				margin: auto;
			}
			@media(max-width: 992px){
				padding-right: 0;
				width: 90%;
				&:before{
					content: none;
				}
				&:after{
					@include triangle('right', $electric-blue, 10px);
					position: absolute;
					right:-30px;
					top: 0;
					bottom: 0;
					margin: auto;
				}
			}
		}
	}
}
