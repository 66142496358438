#info {
  header.container-fluid {
    @include single-row-header;
    @include banner;
    @include banner-electric-blue;
  }

   h1 {
  	margin-top: 45px;
  }


}
