#problemLanding {
  h1{
    position: relative;
    // &:after{
    //   content: "";
    //   width: 130px;
    //   height: 130px;
    //   background-image: url('https://s3-us-west-1.amazonaws.com/tla-craft-assets/images/static/Icon-Problem-of-Practices@2x-1.png');
    //   background-position: center;
    //   background-size: cover;
    //   position: absolute;
    //   right: 0;
    //   top: -40px;
    //   border-radius: 100%;
    // }
  }
	header.container-fluid {
		@include single-row-header;
		@include banner;
    @include banner-electric-blue;
    @media(max-width:767px){
      height: 275px;
    }
		.header-text {
      padding-top: 40px;
      h4{
        font-size: 1.75rem;
        color: white;
      }
    }
    .container{
      height: 215px;
      display: flex;
      align-items: center;
      @media(max-width:767px){
        align-items: initial;
      }
      .row{
        width: 100%;
        .col-xs-12{
          @media(min-width:767px){
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: row-reverse;
          }
          img{
            width: 130px;
            height: 130px;
            border-radius: 100%;
            margin-bottom: $space-md;
          }
        }
      }
    }
  }

  .navbar.is-sticky {
    position: fixed;
    top: 70px;
    z-index: 1;
  }
  .social-bar {
    position: absolute;
    right: 0;
  }
  .col-lg-9{
    position: initial;
  }
  .social-bar.is-sticky {
    position: fixed;
    top: 43px;
  }
  .page-intro{
    p{
      color: $electric-blue;
      margin-bottom: 0;
    }
  }

  h3{
    &.arrow{
      margin-top: $space-xl;
      position: relative;
      cursor: pointer;
      text-transform: uppercase;
      color: $electric-blue;
      padding-left: $space-lg;
      font-size: 24px;
      font-weight: 600;
      span{
        position: absolute;
        top:0;
        left: -$space-md;
        color: $electric-blue;
        cursor: pointer;
        transform: rotate(90deg);
      }    
    }
  }
  
  .editorial-row,
	.author-bio {
		@include preview-xl;
  }
  
  .editorial-row{
    height: 0px;
    overflow: hidden;
    display: block;
    align-items: center;
    min-height: 0;
    justify-content: center;
    // @media(min-width:992px){
    //   display: flex;
    // }
    .preview-image, .preview-content{
      display: inline-block;
      vertical-align: middle;
    }
    .preview-image{
      width: 100%;
      img{
        width: 60% !important;
        margin: auto;
      }
      @include media-breakpoint-up(lg){
        width: 15%;
        margin-bottom: 32px;
        img{
          width: 100% !important;
          margin: auto;
        }
      }
    }
    .preview-content{
      width: 100%;
      @include media-breakpoint-up(lg){
        width: 80%;
        h3{
          font-size: 30px;
        }
      }
      
    }
  }

	@media (max-width: 34em) {
		.editorial-row .preview-image {
			flex-direction: row;
			width: 100%;
			justify-content: space-between;

			.author-info {
				text-align: right;
			}

			img {
				width: 30%;
			}
		}
	}

}