#sub-topics {

  header.container-fluid {
    @include single-row-header;
    @include banner;
    @include banner-verdigris;

    .container {
      padding: 0;
    }

  }

  h1 {
    @include page-title;
  }

  h2 {
    font-size: 30px;
    color: $verdigris;
    margin: 20px auto;
  }

  .card {
    height: 425px;
    overflow: hidden;
    .card__labels p {
      line-height: 24px;
    }
  }

  .sub-topic-list {
    position: relative;
    height: 100%;

    .pagination {
      position: absolute;
      top: 50%;

      &:last-child {
        right: 0;
      }
    }

  }


}
