ul.tabs{
  margin: 0px;
  padding: 0px;
  list-style: none;
  display: flex;
}
ul.tabs li{
  background: none;
  color: #222;
  display: inline-block;
  padding: 10px 15px;
  cursor: pointer;
}

ul.tabs li.current{
  background: #eff8fa;
  color: #222;
  border-top: 3px solid $electric-blue;
}

.tab-content{
  display: none;
  background: #eff8fa;
  padding: 15px;

  .topic-intro__graphic{
    margin-bottom: 32px;
  }

  .count-display{
    display: inline-block;
    p{
      display: inline-block;
    }
  }

  .topic-intro__text{
    ul{
      display: flex;
      flex-wrap: wrap;
      li{
        width: 50%;
      }
    }
  }

  .btn--topic{
    margin: 4px 0;
    word-wrap: break-word;
    min-height: 48px;
    height: auto;
    padding: 0 32px;
    white-space: initial;
    line-height: 18px;
    .fa-info-circle{
      right: 10px !important;
    }
  }

  @media(min-width: 767px){
    padding: 0px 64px;

    .topic-navs, .topic-intro{
      padding: 1.1em !important;
    }
  }
}

.tab-content.current{
  display: inherit;
}