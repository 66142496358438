@mixin center-stack-on-mobile {
	@include media-breakpoint-down(md) {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		text-align: center;
		> * {
			margin: $space-sm 0;
			padding-top: 10px;
			padding-bottom: 5px;
		}
	}
}

footer {
	background-color: #F7F7F7;
	padding: $space-xl 0;
	.brand {
		@include center-stack-on-mobile;
		img {
			display: block;
			width: 245px;
			height: 95px;
		}
	}
	.links {
		@include center-stack-on-mobile;
		align-items: center;
		justify-content: center;
		width: 55%;
		ul {
			@include list-no-style;
			@include center-stack-on-mobile;
			margin: $space-md 0;
			padding: 0;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
			text-align: center;
			a {
				@include link-style($electric-blue);
				font-size: 19px;
			}
			li {
				padding-top: 15px;
				margin: 0;
				@include media-breakpoint-up(md) {
					margin-right: 28px;
				}
			}
		}
		@media (max-width: 544px){
			width: 100%;
		}
	}
	.connect {
		@include center-stack-on-mobile;
		align-items: flex-end;
		width: 20%;
		.connect__content {
			h6 {
				color: $electric-blue;
				font-size: 16px;
				font-size: 18px;
			}
			a {
				@include link-style($electric-blue, underline);
			}
			.social-links {
				margin-top: 12px;
				margin-bottom: $space-md;
				width: 100%;
				display: flex;
				justify-content: space-between;
				.fa-facebook {
					color: #24589f;
				}
				.fa-twitter {
					color: #1dafef;
				}
				.fa-pinterest{
					color: #e9313f;
				}
			}
		}
		@media (max-width: 544px){
			width: 100%;
		}
	}
	.copyright {
		@include center-stack-on-mobile;
		margin-top: 14px;
		font-size: 14px;
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		p {
			margin: 0;
			color: $light-slate-gray;
			a {
				@include link-style($light-slate-gray, underline);
			}
		}
		.copyright__logo {
			height: 43px;
			width: 91px;
		}
	}
	.modal{
		background-color: rgba(0, 84, 139, .5);
		a{
			color:#0275d8;
		}
		h4 {
			font-size: 24px;
			color: black;
		}
		p{
			color: #868a92;
		}
		label{
			color: #868a92;
		}
		.black{
			color: black;
			font-weight: 600;
		}
	}
	.modal-content{
		border-radius: initial;
	}
	.modal-title{
		color: $mantis;
	}
	.modal-dialog{
		height: 65%;
	}
	.modal-body{
		background-color: initial;
		label{
			display: inline-block;
		}
		.button{
			color: $white;
			background-color: $electric-blue;
			border: none;
			font-size: 20px;
			font-weight: 600;
			width: 100%;
			margin-top: 10px;
			cursor: pointer;
		}
	}
	
}
