#home {
	h1,
	h2 {
		font-weight: 100;
		line-height: 48px;
	}
	h2 {
		color:	$white; 
		// margin-top: 60px;
		margin-bottom: 44px !important;
	}
	p {
		color: $taupe-gray;
		a {
			@include link-style($electric-blue);
		}
	}

	.buttons {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.banner {
		h1 {
			margin: 128px 0;
			margin-bottom: 0px;
			font-size: 48px;

			@media(max-width: 767px){
				margin-top: 0;
			}
		}
		.has-arrow-down {
			@include link-style($white);
		}
	}

	h5{
		color: $electric-blue;
		font-size: 28px;
		line-height: 36px;
	}

	h4{
		svg{
			display: inline-block;
			top: 3px;
			position: relative;
			margin-right: 8px;
		}
	}

	section{
		padding: 32px 0;
		@media(min-width: 767px){
			padding: 64px 0;
		}

		&.home-banner-cards{
			background:linear-gradient(90.61deg, #6CC0B5 25.19%, #3782C5 93.07%);
			
			h1,h2{
				padding: 0 24px;
				@media(min-width: 767px){
					padding: 0 48px
				}
			}

			h2{
				@media(max-width: 767px){
					font-size: 1.8rem;
					margin-bottom: 0 !important;
				}
			}

			@media(min-width:992px){
				max-height: 514px;
			}

			@media (max-width:992px){
				.col-xs-12{
					margin-bottom: 32px;
				}
			}
			
			.container{
				margin-top: 32px;
				transform: translateY(40px);
			}

			.banner-card{
				background-color: $white;
				padding: 18px 20px;
				background: #FFFFFF;
				display: table;
				position: relative;
				transition: all .5s ease-in-out;
				margin: auto;
				cursor: pointer;

				&:hover{
					box-shadow: 0px 20px 20px rgba(5, 52, 141, 0.12);
				}
				
				@media(min-width:992px){
					min-height: 360px;
				}

				img{
					margin-bottom: 16px;
					min-height: 215px;
					object-fit: contain;
					@media(min-width: 992px){
						margin-top: -100px;
					}
					&[src="https://bplawassets.learningaccelerator.org/images/Audience@2x.png"]{
						padding: 29px 0;
					}
				}
				
				h4{
					font-size: 22px;
					color: $electric-blue;
					text-align: left;
				}
				p{
					font-size: 16px;
					line-height: 26px;
					text-align: left;
				}
				.button{
					background-color: $sky-blue;
					cursor: pointer;
					color: $white;
					border-radius: 52px;
					margin: auto;
					left: 0;
					right: 0;
					bottom: 16px;
					padding: 8px 26px;
					width: fit-content;
					display: table;
					text-decoration: none;

					@media(min-width: 992px){
						position: absolute;
					}

					&:hover{
						background-color: $electric-blue;
					}

					&.sa{
						left: -57px;
					}
				}
			}
		}

		&.home-search{
			padding: 128px 0;
			padding-bottom: 32px;
			text-align: center;
			background-color: #EDF2F6;
			h2{
				color: $electric-blue;
				font-weight: 600;
			}

			form{
				input.query-input{
					border: 1.3px solid #00548B;
					box-sizing: border-box;
					border-radius: 11.6px;
					font-size: 16px;
					padding: 4px 16px;
					margin: auto;
					margin-bottom: 16px;
					display: block;

					@media(min-width: 992px){
						min-width: 468px;
						display: inline;
					}
				}
				input[type="submit"]{
					background: #00548B;
					border-radius: 30px;
					color: $white;
					padding: 4px 26px;
					width: fit-content;
				}
			}
		}

		&.home-targeted-resource,
		&.home-guides{
			h4{
				color: $electric-blue;
				font-size: 28px;
				span{
					padding: 0;
					color: $copyBlack;
					font-weight: 300;
					@media(min-width: 992px){
						padding-left: $space-lg;
					}
				}
			}
			.img{
				border-radius: 100%;
				height: 100px;
				width: 100px;
				background-position: center;
				background-repeat: no-repeat;
				background-size: cover;
				margin: auto;
				display: block;
				margin-bottom: $space-md;
			}
		}

		&.home-targeted-resource{
			
			a{
				@include overlay-link;
			}
			
			.col-xs-12{
				padding: 0;
				margin-bottom: 32px;
			}

			h5{
				text-align: center;
			}

			h4{
				margin-bottom: 64px;
			}

			h2{
				color: $taupe-gray;
				font-weight: normal;
			}
		}

		&.home-guides{
			background-color: #EDF2F6;
			h4{
				margin-bottom: 32px;
				margin-bottom: 64px;
				position: relative;
				div{
					display: inline;
				}
				span{
					padding: 0;
					color: $copyBlack;
					font-weight: 300;
				}

				@media(min-width: 992px){
					margin-left: 10px;
					padding-left: 186px;
					
					svg{
						position: absolute;
						left: 0;
					}

					div{
						display: none;
					}
					&:before{
						content:"Guidance: ";
						position: absolute;
						left: 40px;
					}
					// &:after{
					// 	content:"";
					// 	border-bottom: 3px solid $electric-blue;
					// 	position: absolute;
					// 	bottom: -16px;
					// 	left:50px;
					// 	width: 90px;
					// 	height: 2px;
					// }
				}
			}
			.img{
				width: 120px;
				height: 120px;
				padding: 8px;
				margin-top: 0;
			}

			a{
				@include overlay-link;
			}

			.copy{
				width: 65%;
				min-height: 225px;
				p{
					font-size: 18px;
				}

				@media(max-width:992px){
					width: 100%;
				}
			}

			.col-xs-12{
				// margin-bottom: 32px;
				padding:0;
				min-height: 290px;

				&:last-child{
					margin-bottom: 0;
				}
				
				@media(min-width: 992px){
					display: flex;
					align-items: center;
				}
			}
		}

		&.home-latest-content{
			overflow-x: hidden;
			span{
				padding: 0;
				color: $copyBlack;
				font-weight: 300;
			}
			.new{
				position: absolute;
				top: 8px;
				left: 32px;
				background-color: $cadmium-orange;
				border-radius: 6px;
				font-size: 14px;
				color: $white;
				padding: 4px 12px;
			}

			.col-xs-12{
				margin-bottom: 32px;
			}

			a{
				@include overlay-link;
			}

			h4{
				color: $electric-blue;
				font-size: 28px;
				margin-bottom: 32px;
				span{
					color: $taupe-gray;
					display: block;
					@media(min-width: 992px){
						padding-left: 265px;
						display: inline-block;
						margin-top: -32px;
					}
				}

			}

			h5{
				font-size: 18px;
				line-height: 24px;
				font-weight: 300;
			}

			img{
				margin: auto;
				margin-bottom:20px;
				min-height: 176px;
			}
			.slick-next,
			.slick-prev{
				color: $taupe-gray;
			}
		}

		&.home-core-content{
			background-color: #EDF2F6;
			h4{
				color: $electric-blue;
				font-size: 28px;
				margin-bottom: 64px;
				span{
					padding: 0;
					color: $copyBlack;
					font-weight: 300;
					display: block;
					@media(min-width: 992px){
						padding-left: 300px;
						display: inline-block;
						margin-top: -32px;
					}
				}
			}

			a.overlay-link{
				@include overlay-link;
			}

			.row{
				@media(min-width:992px){
					& > div:first-child  {
						padding-left: 0;
						@media(max-width: 992px){
							margin-bottom: 32px;
						}
					}
					& > div:last-child  {
						padding-right: 0;
					}
				}

			}

			.grid-content{
				min-height: 460px;
				img{
					margin: auto;
					margin-bottom: 20px;
					margin-top: 20px;
					display: block;
					width: 100%;
				}
				.img{
					background-size: cover;
					background-repeat: no-repeat;
					background-position: center;
					width: 100%;
					min-height: 190px;
					margin-bottom: 20px;
				}
				h6{
					margin-bottom: 20px;
					font-size: 22px;
					color: $electric-blue;
				}
				p{
					font-size: 16px;
				}

				&.col-xs-12{
					@media(min-width:992px){
						&:nth-child(even){
							padding-right: 0;
						}
						&:nth-child(odd){
							padding-left: 0;
						}
					}

				}
			}

			.row-content{
				.col-xs-12{
					padding:0;
				}

				a{
					text-decoration: none;
				}
	
				img{
					width: 25px;
					margin-top:-8px;

					&[src="https://bplawassets.learningaccelerator.org/images/icons/Icon-Do.png"]{
						height: 25px;
						width: auto;
					}
				}
				span{
					color: $mantis;
					padding-left: 12px;
					font-size: 24px;
				}
				h6{
					font-size: 22px;
					color: $electric-blue;
				}
				p{
					font-size: 16px;
				}
			}
		}

		&.home-testimonials{
			padding: 0;
		}
	}
}
