.btn {
	border-radius: 2px;
}

.btn--blue {
	@include link-style($white);
	text-transform: uppercase;
	background-color: $electric-blue;
	border-bottom: 3px solid $true-blue;
	font-size: 16px;
	line-height: 30px;
	letter-spacing: 0.1rem;
}

.btn--blue-border {
	@include link-style($true-blue);
	color: $true-blue;
	padding: 0 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 48px;
	border-radius: 4px;
	border: 1px solid $true-blue;
	margin: $space-md 0;
	p{
		margin-bottom: 0px;
	}
	&.active {
		background-color: $true-blue;
		color: $white;
	}
}
