body.virtualSchools {
  #see{
    header.container-fluid{
      .header-top {
        // padding: 50px 0;
        background-image: none;
    
        // span {
        //   display: block;
        //   color: $white;
        //   font-size: 14px;
        //   letter-spacing: .1em;
        //   text-transform: uppercase;
        // }

        &:before{
          background: linear-gradient(180deg, #3882C5 9.62%, #42D5C3 108.61%);
          opacity: 1;
        }
    
        h1 {
          font-size: 54px;
          line-height: 62px;
          font-weight: 400;
          margin: 0;
          color: $white;
        }
    
        // svg {
        //   display: block;
        //   margin: auto;
        // }
      }
    }
  }
}