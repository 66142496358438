.custom-input {
  display: flex;
  margin: 2rem 0 5rem;
  position: relative;
  width: 100%;

  &.required label span {
    &:after {
      content: " *";
      color: #999;
    }

    &.char-allowed-count:after, &.char-remain-count:after {
      content: "";
    }
  }

  label {
    display: inline-block;
    margin: 0;
    opacity: 1;
    padding: 0;
    position: relative;
    width: 100%;
    cursor: default;

    &.is-disabled {
      pointer-events: none;
    }

    input, select, textarea {
      background-color: transparent;
      box-shadow: 0 0 0 0 #000;
      caret-color: #000;
      color: #000;
      font-size: 1.6rem;
      height: 30px;
      line-height: 30px;
      padding: 0;
      transition: all .2s;
      -webkit-appearance: none;
      width: 100%;
      z-index: 2;
    }

    input.is-invalid, select.is-invalid, textarea.is-invalid {
      border-color: $cadmium-orange1;
    }

    input.is-invalid {
      &.input--clicked, &:active, &:disabled, &:focus, &:hover {
        border-color: $cadmium-orange1;
      }
    }

    select.is-invalid {
      &.input--clicked, &:active, &:disabled, &:focus, &:hover {
        border-color: $cadmium-orange1;
      }
    }

    textarea.is-invalid {
      &.input--clicked, &:active, &:disabled, &:focus, &:hover {
        border-color: $cadmium-orange1;
      }
    }

    input.is-invalid:focus, select.is-invalid:focus, textarea.is-invalid:focus {
      box-shadow: 0 2px 0 0 $cadmium-orange1;
    }

    input.is-invalid ~ .invalid-feedback, select.is-invalid ~ .invalid-feedback, textarea.is-invalid ~ .invalid-feedback {
      display: block;
    }

    input::-webkit-input-placeholder, select::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
      font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
      font-weight: 500;
      color: transparent;
      -webkit-transition: color .2s;
      transition: color 0.2s;
    }

    input::-moz-placeholder, select::-moz-placeholder, textarea::-moz-placeholder {
      font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
      font-weight: 500;
      color: transparent;
      -moz-transition: color .2s;
      transition: color 0.2s;
    }

    input:-ms-input-placeholder, select:-ms-input-placeholder, textarea:-ms-input-placeholder {
      font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
      font-weight: 500;
      color: transparent;
      -ms-transition: color .2s;
      transition: color 0.2s;
    }

    input:-moz-placeholder, select:-moz-placeholder, textarea:-moz-placeholder {
      font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
      font-weight: 500;
      color: transparent;
      -moz-transition: color .2s;
      transition: color 0.2s;
    }

    input:focus::-webkit-input-placeholder, select:focus::-webkit-input-placeholder, textarea:focus::-webkit-input-placeholder, input:focus::-moz-placeholder, select:focus::-moz-placeholder, textarea:focus::-moz-placeholder, input:focus:-ms-input-placeholder, select:focus:-ms-input-placeholder, textarea:focus:-ms-input-placeholder, input:focus:-moz-placeholder, select:focus:-moz-placeholder, textarea:focus:-moz-placeholder {
      color: #999;
    }

    input {
      &.input--hover, &:hover {
        background-color: transparent;
        box-shadow: none;
        outline: 0;
      }
    }

    select {
      &.input--hover, &:hover {
        background-color: transparent;
        box-shadow: none;
        outline: 0;
      }
    }

    textarea {
      &.input--hover, &:hover {
        background-color: transparent;
        box-shadow: none;
        outline: 0;
      }
    }

    input {
      &.input--clicked, &:active, &:focus {
        background-color: transparent;
        box-shadow: 0 2px 0 0 #000;
        outline: 0;
      }
    }

    select {
      &.input--clicked, &:active, &:focus {
        background-color: transparent;
        box-shadow: 0 2px 0 0 #000;
        outline: 0;
      }
    }

    textarea {
      &.input--clicked, &:active, &:focus {
        background-color: transparent;
        box-shadow: 0 2px 0 0 #000;
        outline: 0;
      }
    }

    input {
      &.disabled, &:disabled {
        background-color: transparent;
        box-shadow: none;
        cursor: not-allowed;
        opacity: .5;
        outline: 0;
      }
    }

    select {
      &.disabled, &:disabled {
        background-color: transparent;
        box-shadow: none;
        cursor: not-allowed;
        opacity: .5;
        outline: 0;
      }
    }

    textarea {
      &.disabled, &:disabled {
        background-color: transparent;
        box-shadow: none;
        cursor: not-allowed;
        opacity: .5;
        outline: 0;
      }
    }

    input {
      &.disabled ~ span, &:disabled ~ span {
        opacity: 0.5;
      }
    }

    select {
      &.disabled ~ span, &:disabled ~ span {
        opacity: 0.5;
      }
    }

    textarea {
      &.disabled ~ span, &:disabled ~ span {
        opacity: 0.5;
      }
    }

    input.is-invalid:-moz-read-only, select.is-invalid:-moz-read-only, textarea.is-invalid:-moz-read-only, input.is-invalid:read-only, select.is-invalid:read-only, textarea.is-invalid:read-only {
      border-color: $cadmium-orange1;
    }

    input:-moz-read-only, select:-moz-read-only, textarea:-moz-read-only, input:read-only, select:read-only, textarea:read-only {
      background-color: transparent;
      box-shadow: none;
      cursor: not-allowed;
      opacity: .5;
      outline: 0;
    }

    input:-moz-read-only ~ span, select:-moz-read-only ~ span, textarea:-moz-read-only ~ span, input:read-only ~ span, select:read-only ~ span, textarea:read-only ~ span {
      opacity: 0.5;
    }

    span:not(.char-remain-count):not(.char-allowed-count) {
      color: #999;
      font-size: 1.6rem;
      height: 30px;
      left: 0;
      line-height: 30px;
      opacity: 1;
      pointer-events: none;
      position: absolute;
      top: 0;
      transition: all .2s;
      z-index: 1;
      -webkit-font-smoothing: antialiased;
      white-space: nowrap;
    }

    input {
      border-bottom: 2px solid #000;
      border-radius: 0;

      &.input--clicked ~ span:not(.char-remain-count):not(.char-allowed-count), &:focus ~ span:not(.char-remain-count):not(.char-allowed-count), &:not(:placeholder-shown) ~ span:not(.char-remain-count):not(.char-allowed-count) {
        color: #999;
        font-size: 1.2rem;
        top: -2rem;
        font-family: $default-font;
        font-weight: 400;
      }
    }

    select {
      //background: transparent url(../images/vectors/chevron-down-small.svg) no-repeat right 1rem center;
      background-size: 13px;
      border-bottom: 2px solid #000;
      border-radius: 0;
      box-shadow: 0 0 0 0 #000;
      caret-color: #000;
      color: #000;
      font-size: 1.6rem;
      height: 30px;
      line-height: 30px;
      padding: 0;
      transition: all .2s;
      width: 100%;
      z-index: 2;
      -webkit-appearance: none;

      option {
        opacity: 0;
      }

      ~ span:not(.char-remain-count):not(.char-allowed-count):not(.full-label) {
        color: #999;
        font-size: 1.2rem;
        top: -2rem;
        left: 0;
        font-family: $default-font;
        font-weight: 400;
      }

      &.hidden {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }

      &.is-invalid ~ button {
        border-bottom: 2px solid $cadmium-orange1;

        &:focus {
          box-shadow: 0 2px 0 0 $cadmium-orange1;
        }

        &.open {
          border-bottom: 2px solid transparent;

          &:focus {
            border-bottom: 2px solid transparent;
          }
        }
      }
    }

    textarea {
      border: 2px solid #000;
      border-radius: 8px;
      padding: 20px;
      min-height: 170px;
      margin-top: 8px;

      ~ span:not(.char-remain-count):not(.char-allowed-count) {
        left: 20px;
        top: 30px;
        transition: .1s;
        max-width: calc(100% - 30px);
        white-space: normal;
      }

      &.input--clicked ~ span:not(.char-remain-count):not(.char-allowed-count), &:focus ~ span:not(.char-remain-count):not(.char-allowed-count), &:not(:placeholder-shown) ~ span:not(.char-remain-count):not(.char-allowed-count) {
        color: #999;
        font-size: 1.2rem;
        top: -2rem;
        left: 0;
        font-family: $default-font;
        font-weight: 400;
      }

      &:focus {
        box-shadow: 0 0 0 2px #000;
      }

      &.is-invalid:focus {
        box-shadow: 0 0 0 2px $cadmium-orange1;
      }
    }

    &.has-focus span:not(.char-remain-count):not(.char-allowed-count), &.has-value span:not(.char-remain-count):not(.char-allowed-count) {
      color: #999;
      font-size: 1.2rem;
      top: -2rem;
      font-family: $default-font;
      font-weight: 400;
    }

    &.has-focus.is-textarea span:not(.char-remain-count):not(.char-allowed-count), &.has-value.is-textarea span:not(.char-remain-count):not(.char-allowed-count) {
      top: -2rem;
      left: 0;
    }
  }
}

.custom-input__select-button {
  //background: transparent url(../images/vectors/chevron-down-small.svg) no-repeat right 1rem center;
  background-size: 13px;
  border-bottom: 2px solid #000;
  box-shadow: 0 0 0 0 #000;
  color: #000;
  display: flex;
  font-size: 1.6rem;
  height: 30px;
  left: 0;
  line-height: 30px;
  padding: 0 50px 0 0;
  text-align: left;
  transition: box-shadow .2s;
  width: 100%;
  z-index: 2;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-appearance: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  font-weight: 500;

  &:active, &:focus {
    outline: none;
    box-shadow: 0 2px 0 0 #000;
  }

  em {
    font-style: normal;
    font-weight: 500;
  }

  &.open, &.show {
    //background: #000 url(../images/vectors/chevron-up-small-white.svg) no-repeat right 1.5rem top 2rem;
    background-size: 13px;
    color: $white;
    z-index: 501;
  }

  &.open ~ span:not(.char-remain-count):not(.char-allowed-count), &.show ~ span:not(.char-remain-count):not(.char-allowed-count) {
    opacity: 0;
  }

  &.open em, &.show em {
    left: 20px;
    position: absolute;
    top: 15px;
    z-index: 501;
  }
}

.custom-input__select-buttoninput:-moz-read-only, .custom-input__select-buttontextarea:-moz-read-only, .custom-input__select-button:disabled, .custom-input__select-buttoninput:read-only, .custom-input__select-buttontextarea:read-only {
  background-color: transparent;
  box-shadow: none;
  cursor: not-allowed;
  opacity: .5;
  outline: 0;
}

.custom-input__select-buttoninput:-moz-read-only ~ span, .custom-input__select-buttontextarea:-moz-read-only ~ span, .custom-input__select-button:disabled ~ span, .custom-input__select-buttoninput:read-only ~ span, .custom-input__select-buttontextarea:read-only ~ span {
  opacity: 0.5;
}

.custom-input__select {
  background: #000;
  border: none;
  border-radius: 0 0 30px 30px;
  overflow: hidden;
  position: relative;
  top: 0;
  width: 100%;
  z-index: 500;
  box-shadow: 0 2px 2px 0 hsla(0, 0%, 100%, 0.25);
  display: none;
  position: absolute;
  top: 30px;
  padding: 20px 2rem 0;

  &:after {
    content: "";
    height: 2rem;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: #000;
    background: linear-gradient(0deg, #000, transparent);
  }

  ul {
    max-height: 0;
    overflow: auto;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    transition: 0.2s cubic-bezier(0.38, 0.41, 0.27, 1);
    scrollbar-base-color: #000;
    scrollbar-face-color: $white;
    scrollbar-highlight-color: #000;
    scrollbar-track-color: #000;
    scrollbar-arrow-color: $white;
    scrollbar-shadow-color: #000;
    scrollbar-color: $white #000;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background-color: #000;
      border-radius: 2px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      background-color: hsla(0, 0%, 100%, 0.2);

      &:hover {
        border-radius: 2px;
        background-color: $white;
      }
    }
  }

  &.open ul, &.show ul {
    max-height: 300px;
  }

  li {
    color: #999;
    cursor: pointer;
    display: block;
    font-size: 1.6rem;
    font-weight: 500;
    height: 40px;
    line-height: 18px;
    padding-top: 11px;
    position: relative;
    transition: color .2s;
    user-select: none;
    width: 100%;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;

    &.active, &.selected, &:hover {
      color: $white;
    }

    &.active:before, &:hover.active:before {
      content: "";
      position: absolute;
      right: 20px;
      top: 12px;
      width: 8px;
      height: 12px;
      border: solid $white;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
      z-index: 500;
    }

    &.selected:after {
      content: "";
      border: solid $pear;
      border-width: 0 3px 3px 0;
      height: 12px;
      position: absolute;
      right: 20px;
      top: 12px;
      transform: rotate(45deg);
      width: 8px;
      z-index: 501;
    }

    &:last-child {
      margin-bottom: 2rem;
    }
  }

  &[x-placement*=top] {
    padding-top: 0;
    padding-bottom: 1rem;
    border-radius: 30px 30px 0 0;

    &:after {
      bottom: auto;
      top: 0;
      background: linear-gradient(0deg, transparent, #000);
    }

    li {
      &:last-child {
        margin-bottom: 0;
      }

      &:first-child {
        margin-top: 2rem;
      }
    }
  }

  &.open {
    display: block;
  }
}

.custom-input.is-disabled button {
  cursor: not-allowed;
  border-color: transparent;
  background: #eee;
  color: #666;
}

.custom-input__group {
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;

  label {
    width: 49%;
    border: 2px solid #000;
    border-radius: 8px;
    padding: 12px 24px 12px 12px;
    background-color: $white;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #000;
    transition: all .2s;
    line-height: 1.6rem;
    margin-bottom: 8px;

    &.col-2 {
      width: 49%;
      flex: unset;
      max-width: unset;
      -webkit-box-flex: unset;
    }

    &.col-3 {
      width: 32%;
      flex: unset;
      max-width: unset;
      -webkit-box-flex: unset;
    }

    &.col-4 {
      width: 23%;
      flex: unset;
      max-width: unset;
      -webkit-box-flex: unset;
    }

    &.col-5 {
      width: 19%;
      flex: unset;
      max-width: unset;
      -webkit-box-flex: unset;
    }

    &.col-6 {
      width: 15%;
      flex: unset;
      max-width: unset;
      -webkit-box-flex: unset;
    }

    &.has-focus {
      outline: none;
      box-shadow: 0 0 0 2px #000;
    }

    &.is-checked {
      background: #000;
      color: $white;

      &.has-focus {
        box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.5);
      }

      &:after {
        content: "";
        position: absolute;
        right: 12px;
        top: 8px;
        width: 8px;
        height: 12px;
        border: solid $pear;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg);
      }
    }

    &.is-disabled {
      cursor: not-allowed;
      border-color: transparent;
      background: #eee;
      color: #666;
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    small {
      display: block;
      margin-top: 4px;
      font-size: 1rem;
      font-family: $default-font;
      font-weight: 400;
    }
  }
}

@media only screen and (min-width: 768px) {
  .custom-input__group label {
    width: 32%;
  }
}

@media only screen and (min-width: 992px) {
  .custom-input__group label {
    width: 23%;
  }
}

@media only screen and (max-width: 767px) {
  .custom-input__group label {
    &.col-2-md {
      width: 49%;
    }

    &.col-3-md {
      width: 32%;
    }

    &.col-4-md {
      width: 23%;
    }

    &.col-5-md {
      width: 19%;
    }

    &.col-6-md {
      width: 15%;
    }
  }
}

@media only screen and (max-width: 479px) {
  .custom-input__group label {
    &.col-2-sm {
      width: 49%;
    }

    &.col-3-sm {
      width: 32%;
    }

    &.col-4-sm {
      width: 23%;
    }

    &.col-5-sm {
      width: 19%;
    }

    &.col-6-sm {
      width: 15%;
    }
  }
}

.custom-input {
  .invalid-feedback {
    color: #FA4616;
    height: 30px;
    left: 0;
    line-height: 1.2em;
    position: absolute;
    text-align: left;
    width: 100%;
  }

  .form-text {
    font-family: $default-font;
    font-weight: 400;
    line-height: 1.4rem;
    width: 100%;
    font-size: 1rem;
  }

  .is-textarea .invalid-feedback {
    top: 100%;
  }
}

.dark-theme {
  .custom-input {
    &.required label span:after {
      color: #ccc;
    }

    label {
      input, select {
        border-bottom: 2px solid $white;
        caret-color: $white;
        color: $white;
      }

      input::-webkit-input-placeholder, select::-webkit-input-placeholder, input::-moz-placeholder, select::-moz-placeholder, input:-ms-input-placeholder, select:-ms-input-placeholder, input:-moz-placeholder, select:-moz-placeholder {
        color: transparent;
      }

      input:focus::-webkit-input-placeholder, select:focus::-webkit-input-placeholder, input:focus::-moz-placeholder, select:focus::-moz-placeholder, input:focus:-ms-input-placeholder, select:focus:-ms-input-placeholder, input:focus:-moz-placeholder, select:focus:-moz-placeholder {
        color: #ccc;
      }

      input {
        &.input--clicked ~ span:not(.char-remain-count):not(.char-allowed-count), &:focus ~ span:not(.char-remain-count):not(.char-allowed-count), &:not(:placeholder-shown) ~ span:not(.char-remain-count):not(.char-allowed-count) {
          color: #ccc;
        }
      }

      select {
        &.input--clicked ~ span:not(.char-remain-count):not(.char-allowed-count), &:focus ~ span:not(.char-remain-count):not(.char-allowed-count), &:not(:placeholder-shown) ~ span:not(.char-remain-count):not(.char-allowed-count) {
          color: #ccc;
        }
      }

      input {
        &.input--clicked, &:active, &:focus {
          box-shadow: 0 2px 0 0 $white;
          outline: 0;
        }
      }

      select {
        &.input--clicked, &:active, &:focus {
          box-shadow: 0 2px 0 0 $white;
          outline: 0;
        }
      }

      input.is-invalid:focus {
        box-shadow: 0 2px 0 0 $cadmium-orange1;
      }

      select {
        &.is-invalid:focus {
          box-shadow: 0 2px 0 0 $cadmium-orange1;
        }

        //background: #000 url(../images/vectors/chevron-down-small-white.svg) no-repeat right 1rem center;
        background-size: 13px;
      }

      textarea {
        background-color: $white;
        border-color: transparent;
        caret-color: #000;
        color: #000;

        &::-webkit-input-placeholder, &::-moz-placeholder, &:-ms-input-placeholder, &:-moz-placeholder {
          color: transparent;
        }

        &:focus {
          &::-webkit-input-placeholder, &::-moz-placeholder, &:-ms-input-placeholder, &:-moz-placeholder {
            color: #999;
          }
        }

        ~ span:not(.char-remain-count):not(.char-allowed-count) {
          color: #999;
        }

        &.input--clicked ~ span:not(.char-remain-count):not(.char-allowed-count), &:focus ~ span:not(.char-remain-count):not(.char-allowed-count), &:not(:placeholder-shown) ~ span:not(.char-remain-count):not(.char-allowed-count) {
          color: #ccc;
          top: -2.25rem;
        }

        &.input--clicked, &:active, &:focus {
          box-shadow: 0 0 0 4px hsla(0, 0%, 100%, 0.5);
          outline: 0;
        }

        &.is-invalid:focus {
          box-shadow: 0 0 0 2px $cadmium-orange1;
        }
      }

      span:not(.char-remain-count):not(.char-allowed-count) {
        color: #ccc;
      }

      &.has-focus span:not(.char-remain-count):not(.char-allowed-count), &.has-value span:not(.char-remain-count):not(.char-allowed-count) {
        color: #ccc;
        font-size: 1.2rem;
        top: -2rem;
        font-family: $default-font;
        font-weight: 400;
      }

      &.has-focus.is-textarea span:not(.char-remain-count):not(.char-allowed-count), &.has-value.is-textarea span:not(.char-remain-count):not(.char-allowed-count) {
        top: -2rem;
        left: 0;
      }
    }
  }

  .custom-input__select-button {
    //background: transparent url(../images/vectors/chevron-down-small-white.svg) no-repeat right 1rem center;
    background-size: 13px;
    border-bottom: 2px solid $white;
    color: $white;

    &.open {
      //background: $white url(../images/vectors/chevron-up-small.svg) no-repeat right 1.5rem top 2rem;
      background-size: 13px;
      color: #000;
    }

    &.input--clicked, &:active, &:focus {
      box-shadow: 0 2px 0 0 $white;
      outline: 0;
    }
  }

  .custom-input__select {
    background: $white;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15);

    &:after {
      background: linear-gradient(0deg, $white, hsla(0, 0%, 100%, 0));
    }

    ul {
      max-height: 300px;
      scrollbar-base-color: $white;
      scrollbar-face-color: #000;
      scrollbar-highlight-color: $white;
      scrollbar-track-color: $white;
      scrollbar-arrow-color: #000;
      scrollbar-shadow-color: $white;
      scrollbar-color: #000 $white;

      &::-webkit-scrollbar-track {
        background-color: $white;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 2px;
        background-color: rgba(0, 0, 0, 0.15);

        &:hover {
          border-radius: 2px;
          background-color: #000;
        }
      }

      li {
        font-weight: 500;

        &.active, &.selected, &:hover {
          color: #000;
        }

        &.active:before, &:hover.active:before {
          border: solid #000;
        }
      }
    }
  }

  .custom-input__group label {
    border: 2px solid transparent;
    background-color: #ccc;
    color: #000;

    &.has-focus {
      outline: none;
      box-shadow: 0 0 0 4px hsla(0, 0%, 100%, 0.5);
    }

    &.active:before, &:hover.active:before {
      content: "";
      border: solid rgba(0, 0, 0, 0.75);
    }

    &.is-checked {
      background: $white;
      color: #000;

      &.has-focus {
        box-shadow: 0 0 0 4px hsla(0, 0%, 100%, 0.5);
      }
    }

    &.is-disabled {
      background: #333;
      color: #999;
    }
  }

  .custom-input {
    .invalid-feedback {
      color: $cadmium-orange1;
    }

    .form-text {
      color: #e1e1e1;
    }
  }
}

body.custom-input-opened {
  cursor: pointer;
}