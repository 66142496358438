#problem {
  table, th, td {
    border: 1px solid black;
  }
  p{
    margin-bottom: 32px;
    display: block !important;
  }
  td{
    padding: 8px;
    p, li{
      font-size: 1rem !important;
    }
  }
	header.container-fluid {
		@include single-row-header;
		@include banner;
		@include banner-electric-blue;

		.header-text {
      padding-top: 40px;
      h4{
        font-size: 1.75rem;
        color: white;
      }
		}
  }
  #sideNav{
    background-color: #ececec;
    max-width: 250px;
    padding: 16px;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: left;
    color: #868a92;
    li{
      padding-bottom: 5px;
      a{
        color: $taupe-gray;
        font-size: 20px;
        padding: 0;
        &.active{
          color: #ef8322;
        }
      }
    }
    
  }
  .navbar.is-sticky {
    position: fixed;
    top: 70px;
    z-index: 1;
  }
  .social-bar {
    position: absolute;
    right: 0;
  }
  .col-lg-9{
    position: initial;
  }
  .social-bar.is-sticky {
    position: fixed;
    top: 43px;
  }
	.page-content {
    .keytakeaways{
      display: flex;
      align-items: center;
      @media(min-width:767px){
        min-height: 356px;
      }
    }
    .keytakeaways{
      // @media(max-width:992px){
      //   img{
      //     width: 50% !important;
      //   }
      // }
      @media(max-width:370px){
        img{
          width: 100% !important;
        }
      }
    }
    #full {
      margin-bottom: 32px;
      aside{
        .mention {
          width: 100%;
          div{
            height: initial;
          }
          img{
            max-width: 100%;
          }
        .strategy-preview, 
        .nonfeatured-artifact {
          float: left;
          background-color: #f8f8f8;
          // @media(max-width:992px){
          //   width: 100% !important;
          // }
          @media(min-width:767px) and (max-width: 992px){
            width: 100% !important;
          }
          @media(min-width: 767px){
            margin: 5px;
          } 
          a{
            width: 100%;
            height: 110px;
            overflow: hidden;
            @media(max-width: 992px){
              height: 300px;
            }
            @media(max-width:600px){
              height: auto;
            }
            img{
              width: 100%;
              border-radius: 0;
              margin: 0;
            }
          }
          .preview-icon{
            display: inline-block;
          }
          .preview-content{
            width: 100%;
            padding: 0 10px 10px 10px;
            display: inline-grid;
            span{
              font-size: .8rem;
            }
            h3{
              border-top: 2px solid #ececec;
              line-height: 10px;
              padding-top: 5px;
              a{
                font-size: 1rem;
                line-height: 20px;
                
              }
            }
          }
          width: 28%;
          @media (min-width:992px) and (max-width:1200px){
            width: 45%;
          }
          @media (min-width:992px){
            height: 240px;
          }
          display: inline-block;
          flex-direction: column;
          &:hover{
            text-decoration: none;
            transform: scale(1.05);
            transition: all .2s ease-in;
            box-shadow: 0 6px 0 0 #ef8322, 
          }
        }
      }
    }
    
    }
    .col-lg-3{
      display: flex;
      justify-content: center;
      @media(max-width: 992px){
        justify-content: baseline;
      }
      img{
        width:100%;
        align-self: center;
      }
    }
    .row{
      position: relative;
      .number{
        background-color: $verdigris;
        border-radius: 100px;
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: -50px;
        color: white;
        @media(max-width: 600px){
          width: 30px;
          height: 30px;
          left: -30px;
        }
      }
    }
		aside {
			margin: 0 5px 5px 10px;
			width: 100%;

			@media (min-width: 34em) {
			}

			h3 {
				color: $cadmium-orange;
			}

			img {
				max-width: 60%;
				margin-bottom: 10px;
      }
      
      .aspect-ratio{
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 56%;
        margin-bottom: $space-lg;
        iframe{
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0; top: 0;
          border: none;
        }
      }
      
			.mention {
				width: 100%;
				div {
          width: 48%;
          display: inline-block;
          @media(max-width:992px){
            height: 132px;
          }
        }
        img{
          max-width: 100%;
        }
        
        .strategy-preview,
        .nonfeatured-artifact{
          margin: 15px auto;
          @media(min-width:767px) and (max-width: 992px){
            width: 45% !important;
          }
          .preview-icon {
            width: 28%;
            display: inline-flex;
            img{
              align-self: center;
            }
            @media(min-width:992px) and (max-width:1200px){
              width: 45%
            }
            @media(max-width: 1200px){
              margin: auto;
              display: block;
            }
            @media (min-width:767px) and (max-width: 992px){
              width: 50%;
            }
          }
          .preview-content {
            width: 70%;
            display: inline-block;
            padding-left: 10px;
            // top: -28px;
    				position: relative;
            @media(max-width:1200px){
              width: 100%;
              text-align: center;
            }
            h3 {
              font-size: 13px;
              a{
                font-size: 18px;
              }
            }
          }
        }
			}
		}
  }
  
  .body-copy{
    h3{
      font-size: 1.5rem;
      font-weight: 600;
    }
    ol li, ul li{
      font-size: 1.2rem;
    }
  }

	.editorial-row,
	.author-bio {
		@include preview-xl;
	}

	@media (max-width: 34em) {
		.editorial-row .preview-image {
			flex-direction: row;
			width: 100%;
			justify-content: space-between;

			.author-info {
				text-align: right;
			}

			img {
				width: 30%;
			}
		}
	}

	@media (max-width: 992px){
		.page-content {
      .aspect-ratio{
        width: 100% !important;
        padding-bottom: 51% !important;
        iframe{
          width: 92% !important;
        }
      }
			aside {
        margin: 0;
        img{
          max-width: 100% !important;
        }
				.mention {
          .strategy-preview,
          .nonfeatured-artifact{
            flex-direction: row;
            @media(max-width: 400px){
              flex-direction: column;
            }
            justify-content: center;
            align-items: center;
            padding: 0 !important;
          }
					div {
						width: 100% !important;
						.preview-icon{
							display: flex;
              align-items: center;
              width: 100% !important;
              @media(max-width: 400px){
                justify-content: center;
              }
						}
						.preview-content{
              text-align: center;
              padding: 0;
							a{
                font-size: 18px;
                @media(max-width: 400px){
                  font-size: 13px;
                }
							}
						}
					}
				}
			}
		}
  }
  @media(max-width: 600px){
    div[style="margin-left:60px;margin-bottom:20px;"]{
      margin-left: 0px !important;
    }
  }
}