.grid__item {
	overflow: hidden;
	padding-bottom: $space-md;
	height: 150px;
	a {
		text-decoration: none;
	}
	@include media-breakpoint-down(md) {
		width: 100%;
	}
	display: inline-block;
	left: 0px !important;
	top: 0px !important;
	position: relative !important;
	transform: none !important;
	position: relative;
	.card {
		padding: $space-md;
		height: 100%;
	}
	&.grid__item--one-third {
		height: 400px;
		width: calc(33.33% - 6px);
		@media (max-width: 767px){
			width: 100% !important;
			height: 400px;
		}
	}
	&.grid__item--two-third {
		height: 400px;
		width: calc(67% - 6px);
	}
	&.grid__item--full {
		width: 100%;
		height: 211px;
		.card {
			display: flex;
			flex-direction: row;
			position: relative;
			.img-container {
				width: 350px !important;
				img {
					width: 200px !important;
				}
				#rect {
					display: block !important;
				}
				#hero {
					display: none;
				}
			}
			.desc {
				position: absolute;
				left: 200px;
				top: 16px;
				margin-left: 30px;
				h1 {
					font-size: 25px;
					color: $electric-blue !important;
					border-top: 0;
				}
			}
			.school-data {
				display: block !important;
				width: auto;
				position: absolute;
				top: 16px;
				right: 0px;
				ul {
					@include list-no-style();
					display: flex;
					li {
						border-left: 1px solid $electric-blue;
						padding: 0px 15px 0px 15px;
						font-size: 13px;
						&:first-child {
							border-left: 0px;
						}
					}
				}
			}
			.img-container {
				min-height: 123px;
				height: 123px;
			}
		}
		.card:hover {
			transform: none;
		}

		.virtual-icon{
			position: absolute;
			right: 16px;
			top: 150px;
		}
	}
	
}
