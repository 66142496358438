//formatting helpers
// helpers
@mixin bg-img($img, $size: cover, $posX: center, $posY: center, $repeat: no-repeat) {
	background: url("/assets/images/#{$img}") $posX $posY $repeat;
	background-size: $size;
}

@mixin block-fill-container {
	display: block;
	height: 100%;
	width: 100%;
}

@mixin absolute-center {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	margin: auto;
}

@mixin bg-cover {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}

@mixin bg-contain {
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
}

@mixin img-contain {
	img {
		max-width: 100%;
	}
}

@mixin keep-aspect-ratio($x, $y, $width) {
	position: relative;
	content: "";
	width: $width;
	display: inline-table;
	padding-top: ($y / $x) * $width;
	img,svg {
		display: block;
		width: 100%;
		height: auto;
		position: absolute;
		top: 0;
	}
}

@mixin list-no-style {
	padding: 0;
	margin: 0;
	li {
		list-style: none;
	}
}

@mixin link-style($color, $decor:none) {
	color: $color;
	text-decoration: $decor;
	&:hover,
	&:active,
	&:focus {
		color: $color;
		text-decoration: $decor;
	}
}

@mixin flex-center {
	display: flex;
	align-items: center;
	justify-content: center;
}

@mixin vertical-center {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

// content based

@mixin white-bullet-list {
	& > ul {
		padding: 0;
	}
	li {
		list-style: none;
		background: url("https://s3-us-west-1.amazonaws.com/tla-craft-assets/images/static/arrow%402x.png")
			no-repeat;
		background-size: 14px 14px;
		background-position: 0 7px;
		padding-left: 1.5em;
	}
}

@mixin grey-bullet-list {
	> ul {
		@include list-no-style;
		padding-left: 40px;
		li {
			background: url("https://s3-us-west-1.amazonaws.com/tla-craft-assets/images/static/arrow%402x.png")
				no-repeat;
			background-size: 12px 18px;
			background-position: 0 4px;
			padding-left: 1.5em;
		}
	}
}

@mixin breadcrumb() {
	background-color: transparent;
	li {
		color: white;

		&:before {
			content: ">";
			color: white;
		}

		a {
			color: white;
			text-decoration: underline;
		}
	}

	li:first-child {
		&:before {
			content: "";
		}
	}
}

@mixin page-title() {
	font-weight: 100;
	color: white;

	span {
		font-weight: 600;
	}
}

@mixin page-intro() {
	overflow-y: hidden;

	p,
	ul {
		color: white;
		line-height: 30px;
		font-size: 22px;
		font-weight: 100;
	}
}

@mixin single-row-header() {
	padding: 0;
	width: 100%;
	min-height: 215px;

	.breadcrumb {
		@include breadcrumb;
	}

	h1 {
		@include page-title;
	}

	.page-intro {
		@include page-intro;
		@include white-bullet-list;
	}
}

@mixin sidebar-content() {
	overflow: hidden;
	border-radius: 0 !important;
	width: 100%;
	min-height: 400px;
	border-radius: 0;
	background-color: white;
	border-radius: 4px;
	padding: 10px;
	box-shadow: 0px 3px 1.8px 0.14px rgba(0, 0, 0, 0.1);

	h3 {
		color: $cadmium-orange;
		font-size: 18px;
	}
}

@mixin search-input {
	background-color: transparent;
	color: white;
	border: none;
	padding-left: 0px;
	margin-left: 5px;
	&::placeholder {
		color: white;
	}
}

@mixin graph-label {
	color: $taupe-gray !important;
	fill: $taupe-gray !important;
	font-size: 11px;
}

// tags

@mixin tags {
	.tags {
		.label {
			background-color: lighten($verdigris, 40%);
			color: $verdigris;
			padding: 5px;

			&:hover {
				color: $verdigris;
				background-color: lighten($verdigris, 30%);
			}
		}
	}
}
// previews

@mixin preview-sm {
	width: 100%;
	display: flex;
	margin: 10px auto;
	justify-content: space-between;
	align-items: center;
	h3{
		font-size: 15px;
	}
	.preview-icon {
		display: block;
		width: 15%;

		img {
			width: 100%;
			max-width: 90px;
		}
	}

	.preview-content {
		max-height: 120px;
		overflow: hidden;
		width: 85%;
		padding-left: 10px;
	}

	p {
		font-size: 16px;
		margin: 0;
	}

	a {
		font-size: 18px;
		line-height: 22px;
		color: $celestial-blue;
	}
}

@mixin preview-md {
	width: 100%;
	display: flex;
	margin: 10px auto;
	justify-content: space-between;
	@media(max-width:400px){
		flex-direction: column;
		justify-content: center;
	}

	.preview-icon {
		display: flex;
		width: 80px;
		height: 80px;
		justify-content: center;
		overflow: hidden;
		border: 2px solid $light-gray;
		border-radius: 50%;
		@media(min-width:1200px){
			width: 100px;
			height: 100px;
		}
		@media(max-width:400px){
			margin: auto;
			width: 120px;
			height: 120px;
			margin-bottom: $space-lg;
		}

		img {
			width: 100%;
			align-self: center;
		}
	}

	.preview-content {
		height: 150px;
		overflow: hidden;
		width: 70%;
		@media(max-width:400px){
			width: 100%;
			text-align: center;
		}
	}

	p {
		font-size: 16px;
		margin: 0;
	}
}

@mixin preview-lg {
	width: 100%;
	display: flex;
	margin: 40px auto;
	justify-content: space-between;
	color: $taupe-gray;

	.preview-icon {
		display: block;
		width: 10%;

		img {
			border: 1px solid $light-gray;
			width: 100%;
			border-radius: 50%;
		}
	}

	.preview-content {
		max-height: 120px;
		overflow: hidden;
		width: 85%;

		h3 {
			font-size: 24px;
			color: $ocean-boat-blue;
			line-height: 30px;
			font-weight: 300;
		}

		p {
			font-size: 18px;
			margin: 0;
			font-weight: 300;
			line-height: 24px;
		}

		a {
			font-size: 24px;
			line-height: 30px;
			color: $ocean-boat-blue;
		}

		span {
			font-size: 14px;
			text-transform: uppercase;
		}
	}
}

@mixin preview-xl {
	min-height: 250px;
	display: flex;
	justify-content: space-between;

	@media (max-width: 34em) {
		flex-direction: column;
		align-items: center;
	}

	.preview-image {
		width: 15%;
		display: flex;
		align-items: center;
		flex-direction: column;
		@media (max-width: 34em) {
			width: 50%;
		}

		img {
			border: 1px solid $light-gray;
			display: block;
			width: 100%;
			border-radius: 50%;
			margin-bottom: 20px;
		}

		p {
			margin: 0;
			font-size: 18px;
			line-height: 20px;
			text-transform: uppercase;
			color: $electric-blue;
		}
	}
	.preview-content {
		width: 80%;
		max-height: 100%;
		overflow: hidden;
		text-overflow: ellipsis;

		@media (max-width: 34em) {
			width: 100%;
			text-align: center;
		}

		span {
			color: $taupe-gray;
			text-transform: uppercase;
			font-size: 20px;
		}

		h3 {
			font-size: 35px;
			line-height: 37px;
			font-weight: 300;

			a {
				color: $electric-blue;
			}
		}
	}
}

@mixin filter-sm {
	color: $taupe-gray;
	list-style: none;
	cursor: hand;
	cursor: pointer;
	text-transform: capitalize;
	padding: 7px;
	font-size: 16px;
	line-height: 18px;
}

@mixin selected-filter {
	color: $true-blue;
	position: relative;
	&::before {
		content: '';
		position: absolute;
		background-color: $true-blue;
		top: 9px;
		left: 12px;
		height: 12px;
		width: 12px;
		border-radius: 6px;
		background-size: 12px 12px;
		z-index: 2;
	}
}

@mixin overlay-link{
	position: absolute;
	top:0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 100;
}

@mixin radio-button-circle {
	content: '';
	position: absolute;
	background-color: white;
	border: 1px solid $taupe-gray;
	top: 7px;
	left: 10px;
	height: 16px;
	width: 16px;
	border-radius: 8px;
	background-size: 16px 16px;
}

/// Triangle helper mixin
/// @param {Direction} $direction - Triangle direction, either `top`, `right`, `bottom` or `left`
/// @param {Color} $color [currentcolor] - Triangle color
/// @param {Length} $size [1em] - Triangle size
@mixin triangle($direction, $color: currentcolor, $size: 1em) {
	@if not index(top right bottom left, $direction) {
		@error "Direction must be either top, right, bottom or left.";
	}

	width: 0;
	height: 0;
	content: "";
	z-index: 2;

	$perpendicular-borders: $size solid transparent;

	@if $direction == top {
		border-left: $perpendicular-borders;
		border-right: $perpendicular-borders;
		border-bottom: $size solid $color;
	} @else if $direction == bottom {
		border-left: $perpendicular-borders;
		border-right: $perpendicular-borders;
		border-top: $size solid $color;
	} @else if $direction == right {
		border-bottom: $perpendicular-borders;
		border-top: $perpendicular-borders;
		border-left: $size solid $color;
	} @else if $direction == left {
		border-bottom: $perpendicular-borders;
		border-top: $perpendicular-borders;
		border-right: $size solid $color;
	}
}
