.card {
  height: 100%;
  margin-bottom: 0;
  border: 0;
  border-radius: 0;
  display: flex;
  flex-direction: column;
  background-color: #f8f8f8;
  box-shadow: 0px 6px 0px 0px $light-gray;
  transition: all 0.2s ease-out;
  overflow: hidden;
  h1{
    color: $cadmium-orange !important;
    font-size: 1.1rem;
    position: relative;
    border-top: 2px solid $light-gray;
    padding-top: 11px;
  }
  p{
    &.card__label{
      color: $gray;
      font-size: 13px;
      margin-bottom: 5px;
    }
    font-size: .9rem;
    &:last-child{
      color: $gray;
      text-decoration: none;
    }
  }
  &:hover {
    text-decoration: none;;
    transform: scale(1.05);
    transition: all 0.2s ease-in;
    box-shadow: 0px 6px 0px 0px $cadmium-orange, 0 19px 22px -3px rgba(239, 131, 34, 0.25);
  }

  .card__image {
    @include keep-aspect-ratio(4, 2.5, 100%);
    display: block;
    overflow: hidden;
  }

  .card__content {
    height: auto;
    flex:2;
    padding: 0;
    padding-top: 20px;
    color: $taupe-gray;
    padding: 11px;

    p {
      font-size: 16px;
      line-height: 20px !important;
    }

    .card__title {
      border-top: 2px solid $light-gray;
      color: $cadmium-orange;
      padding-top: 11px;
      font-weight: 300;
      font-size: 20px;
      margin-bottom: 5px;
    }

    .card__labels {
      margin-bottom: 10px;
      p {
        font-size: 11px !important;
        line-height: 15px !important;
        text-transform: uppercase;
        margin-bottom: 0px;
      }
    }

  }

}
