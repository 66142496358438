.navbar.top-nav {
  background-color: $white;
  height: 120px;
  border-radius: 0;
  position: relative;
  padding: 0;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 11px 0 0 rgba(0, 0, 0, 0.15);
  z-index: 9;

  @include media-breakpoint-down(md) {
    height: 55px;
  }

  .navbar-brand {
    width: 20%;
    display: flex;
    align-items: center;
    padding: 0;
    padding-left: 40px;
    overflow: hidden;

    @media (max-width: 1200px) {
      padding-left: 5px;
    }

    .logo {
      margin: 10px;
      height: 35px;
      width: 35px;

      &.anniversary{
       width: 90%;
      }

      @include media-breakpoint-up(xl) {
        margin: 10px;
        height: 55px;
        width: 55px;

        &.anniversary{
          // transform: scale(1.6);
          // left: 32px;
          // position: relative;
        }
      }
    }

    @include media-breakpoint-down(md){
      width: 50%;
    }
  }

  &:after {
    display: none;
  }

  .hamburger {
    background-color: transparent;
    border: none;
  }

  ul.navbar-nav {
    height: 100%;
    width: 100%;

    li.nav-item {
      font-size: 21px;
      border-right: 1px solid $munsell1;
      height: 100%;
      margin: 0px;
      display: inline-flex;
      align-items: center;
      cursor: hand;
      cursor: pointer;
      padding: 0 $space-md;
      width: 20%;

      a.nav-link {
        color: $true-blue;
        width: 100%;
        display: flex;
        justify-content: space-around;

        .nav-link__icon {
          @include vertical-center;
          width: 35px;
          margin-right: $space-md;

          img[alt='Icon Do'] {
            width: 25px;
          }

          img[alt='Icon See'] {
            width: 150%;
            max-width: initial;
          }

          img[alt='Icon Extend'] {
            width: 150%;
            max-width: initial;
          }

          img[alt='Icon Learn'] {
            width: 110%;
            max-width: initial;
          }
        }

        .nav-link__text {
          @include vertical-center;

          h3 {
            font-size: 24px;
            margin: 0;

            @include media-breakpoint-down(lg) {
              font-size: 18px;
            }
          }

          p {
            line-height: 20px;
            font-size: 16px;
            margin: 0;

            @include media-breakpoint-down(lg) {
              font-size: 12px;
              line-height: 16px;
            }
          }
        }
      }

      &.active-button {
        background-color: #ececec;
      }

      &:first-child {
        border-left: 1px solid $munsell1;
      }

      &:last-child {
        border-right: none;
      }

      form.search-form {
        height: $space-lg;
        position: relative;
        border-bottom: 1px solid $true-blue;

        &:focus {
          border-bottom: 1px solid $true-blue;
        }

        img {
          position: absolute;
          display: block;
          height: 24px;
          width: 24px;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
        }

        >input {
          background-color: transparent;
          color: $true-blue;
          border: none;
          padding-left: 0px;
          margin-left: 5px;
          position: relative;
          width: 70%;

          &::placeholder {
            color: $light-gray;
          }
        }
      }
    }
  }

  &.is-sticky {
    position: fixed;
    top: 0px;
    height: 48px;
    width: 100%;
    z-index: 12;
    align-items: center;

    .navbar-brand img {
      width: $space-lg;
      height: $space-lg;
    }

    li.nav-item.submenu-toggle {
      display: flex;
      align-items: center;
      justify-content: center;

      form.form-inline.search-form {
        margin: 0;
      }

      a.nav-link {

        .nav-link__icon,
        .nav-link__text p {
          display: none;
        }
      }
    }
  }
}

.site-nav {
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #eef3f7;

  ul {
    width: 100%;

    .commons-btn {
      float: right;
      position: absolute;
      right: 35px;
      top: 2px;

      a {
        background-color: $cadmium-orange;
        padding: 3px 5px;
        color: white;
        border-radius: 3px;
        font-size: 15px;
        cursor: pointer;
        font-weight: 400;
        letter-spacing: 1px;
      }
    }

    &:before {
      content: '';
      height: 15px;
      width: 100%;
      position: absolute;
      top: 0px;
      z-index: 1;
      background: linear-gradient(90deg,
          #0083ca 0%,
          #46bfb1 26.88%,
          #71bf44 100%);
    }

    @include list-no-style();
    display: flex;

    li {
      position: relative;
      top: 1px;
      padding: 0 32px;
      border-top: 15px solid transparent;
      z-index: 3;
      transition: all .5s ease-in-out;

      // &:nth-child(2){
      // 	border-right: 3px solid $white;
      // }

      &:not(:nth-child(2)) {
        margin-top: 14px;
        border-top: 0;
      }

      &:last-child {
        padding-left: 48px;
        border-right: 3px solid $white;
      }

      a {
        font-size: 20px;
        color: $electric-blue !important;
        font-weight: 400;
        height: 100%;
        display: block;
        cursor: pointer;

        &.non-current {
          font-size: 15px;
          margin-top: 10px;
          color: $mantis !important;

          &:before {
            content: "VISIT";
            position: absolute;
            font-size: 10px;
            top: 0;
            left: 0;
            right: 0;
            margin: auto;
            letter-spacing: 1px;
            text-align: center;
          }
        }
      }

      &.current-site {
        position: relative;

        a {
          @include link-style($mantis);
          position: relative;

          &:after {
            opacity: 0;
          }
        }

        &.active {
          box-shadow: 0 -6px 12px 0 rgba(46, 164, 101, .25);
          background: white;
          transform: scale(1.05);

          &:after {
            content: "";
            position: absolute;
            top: -18px;
            left: 0;
            width: 100%;
            height: 5px;
            background-color: #71bf44;
            opacity: 1;
          }

          a {
            font-weight: 700;
            font-size: 18px;

            &:before {
              opacity: 1;
            }
          }
        }
      }

      &:not(:nth-child(2)) {
        a {
          color: $electric-blue;
          text-decoration: none;
          width: 235px;
          text-align: center;

          &:hover {
            font-weight: 700;
            // transform: scale(1.05)
          }
        }
      }
    }

    &.active {
      &:before {
        top: 50px;
      }
    }
  }
}

.submenu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 2;
  width: 100%;
  background-color: $light-slate-gray;
  border-top: solid 11px #ececec;
  border-bottom: solid 11px #ececec;
  align-items: center;
  justify-content: center;

  a {
    z-index: 2;
    color: white;

    div {
      &:last-child {
        width: 245px;
      }
    }
  }

  .submenu-item {
    display: flex;
    align-items: center;
    position: relative;
    height: 148px;
    font-size: 20px;

    .background-img {
      width: 100px;
      height: 100px;
      background-size: cover;
      background-position: center;
      margin-right: 10px;
      border-radius: 50px;
      background-repeat: no-repeat;
    }

    img {
      display: block;
      height: 100px;
      width: 100px;
      min-width: 100px;
      /* for safari */
      border-radius: 50px;
      margin: 25px;
    }

    img,
    div {
      opacity: 1;
      transition: all 0.5s ease-in-out;
    }

    &:after {
      opacity: 0;
      transition: all 0.5s ease-in-out;
      position: absolute;
      left: 35%;
      top: 50%;
      transform: translateY(-50%);
      content: 'Explore.';
      color: white;
      font-size: 30px;
    }

    &:hover {
      transition: all 0.5s ease-in-out;
      text-decoration: none;
      background-color: $electric-blue;

      img,
      div {
        opacity: 0.1;
        transition: all 0.5s ease-in-out;
      }

      &:after {
        opacity: 1;
        transition: all 0.5s ease-in-out;
      }
    }
  }
}

.mobile-menu {
  @extend .container-fluid;
  max-height: 0;
  transition: 0.6s ease-in-out;
  width: 100%;
  position: fixed;
  top: 55px;
  z-index: 1000;
  background-color: white;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  input {
    width: 68%;
    line-height: 22px;
    padding: 8px;
    border: none;
    border-bottom: 2px solid $electric-blue;
  }

  .btn-search {
    background-color: $true-blue;
    width: 30%;
    color: white;
  }

  &.show {
    padding-top: 20px;
    padding-bottom: 100px;
    max-height: 34em;
  }

  li.nav-item {
    width: 95%;
    padding-top: 10px;
    margin-left: 0 !important;

    span {
      font-size: 1.1rem;
    }
  }

  ul {
    list-style: none;
    padding: 0;
    font-size: 1.2rem;
    text-align: justify;

    &:after {
      /* 5 */
      content: '';
      display: inline-block;
      width: 100%;
    }

    li {
      padding-top: 20px;

      a {
        line-height: 20px;
        display: block;
        text-align: left;
      }
    }
  }

  .menu-container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  .scroll-indicator {
    position: absolute;
    left: 90%;
    font-size: 30px;

    -webkit-animation: bounce 1s infinite;
    animation: bounce 1s infinite;
    transition: all .1s ease-in-out;

    &.hide {
      opacity: 0;
    }
  }

  @-webkit-keyframes bounce {
    0% {
      top: 85%;
    }

    50% {
      top: 90%;
    }

    100% {
      top: 85%;
    }
  }

  @keyframes bounce {
    0% {
      top: 85%;
    }

    50% {
      top: 90%;
    }

    100% {
      top: 85%;
    }
  }
}

#see-menu {
  .container {
    width: 100%;

    .row {
      width: 100%;
    }
  }

  div {
    text-transform: uppercase;
  }

  .thumb-container {
    width: 120px;
    height: 120px;
    margin-right: 40px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 150px;

    i {
      display: flex;
      justify-content: center;
      width: 100px;
      transform: scale(2);
    }

    svg {
      transform: scale(1.2);
      top: -3px;
      position: relative;
    }
  }
}