#topics {
	.card {
		display: block;
		h1,p{
			position: relative;
			overflow: hidden;
		}
		.img-container {
			min-height: 90px;
			overflow: hidden;
			margin-bottom: 10px;
			position: relative;
		}

		&.sa{
			display: inline-block;
			vertical-align: top;
		}
	}
	.landing {
		padding-bottom: 32px;
		.landing__copy {
			margin: 50px 0px 50px;
			p {
				text-align: left;
				color: gray;
			}
		}
		.landing__image {
			text-align: center;
			img {
				width: 75%;
				margin: $space-lg auto;
			}
		}
		.landing__button {
			@include flex-center;
			margin-bottom: $space-lg;
		}
	}
	.topic-intro {
		background-color: $light-cyan;
		padding: $space-lg 0;
		h1 {
			color: $electric-blue;
			font-size: 2.5em;
		}
		.topic-intro__text {
			overflow: initial;
			p {
				color: $gray;
				font-size: 18px;
			}
			ul {
				li {
					color: $gray;
					font-size: 18px;
				}
			}
		}
		.topic-intro__graphic {
			display: flex;
			justify-content: center;
			@media(max-width: 767px){
				display: none;
			}
			img {
				display: block;
				width: 250px;
				height: 250px;
			}
		}
	}

	.sub-topic-preview {
		background-color: $ghost-white;
		border-bottom: solid 11px $platinum;
		align-items: center;

		div {
			min-height: 250px;
		}

		.preview-image {
			@include vertical-center;
			img {
				display: block;
				margin-left: auto;
				margin-right: auto;
				width: 100%;
				height: auto;
				border-radius: 50%;
			}
		}
		.preview-content {
			padding: 20px;
			position: relative;

			p {
				margin: 0;
			}

			a {
				color: $true-blue;
			}
		}

		@media (max-width: 48em) {
			text-align: center;

			div {
				min-height: 0;
			}

			.preview-image img {
				margin-top: 20px;
				width: 35%;
			}
		}

		.preview-link {
			@include vertical-center;
			min-height: 100px;
			@media (min-width: 992px) {
				min-height: 250px;
			}

			.btn-primary {
				border-radius: 20px;
				background-color: $true-blue;
				border-color: $true-blue;
				font-size: 24px;
				transition: all 0.2s ease-in-out;

				&:hover {
					background-color: darken($true-blue, 10%);
					border-color: darken($true-blue, 10%);
					transform: scale(1.05);
				}
			}
		}
	}

	.bottom-nav {
		border-top: 1px solid $platinum;
		border-bottom: 1px solid $platinum;

		h4 {
			font-size: 34px;
			color: $verdigris;
		}
	}

	.topic-navs {
		background-color: $light-cyan;
		.btn--topic {
			position: relative;
		}
		i.fa {
			position: absolute;
			right: 15px;
			top: 15px;
			transform: scale(1.3);
			cursor: pointer;
		}
		i.fa:hover ~ .info {
			display: block;
		}
		.info {
			position: absolute;
			display: none;
			right: -100px;
			top: 64px;
			min-width: 96px;
			z-index: 10;
			background-color: white;
			padding: 20px;
			transition: all 0.7s ease-in-out;
			box-shadow: 5px 5px 8px 4px rgba(0, 0, 0, 0.4);
			&::after {
				@include triangle('top', $white, 20px);
				position: absolute;
				right: 102px;
				top: -19px;
			}
			p {
				color: $electric-blue;
			}
			ul {
				@include list-no-style();
				li {
					color: $electric-blue;
				}
			}
		}
	}
	.grid {
		min-height: 400px;
		margin-bottom: 128px;
	}
	&.conditions-for-success-scale{
		
		.topic-intro__graphic + .topic-intro__text{
			text-align: center;
		}
		.landing__copy{
			margin: 32px 0;
		}
		.landing__image {
			img{
				width: 95%;
				margin: 0;
			}
		}
	}
	.topic-navs{
		@media(max-width:767px){
			padding-top: 32px;
			.col-xs-12{
				display: none;
			}
		}
		h4.do-dropdown{
			text-align: center;
			font-size: 18px;
			color: $electric-blue;
		}
		.do-dropdown{
			display: block;
			margin: auto;
			padding: 8px 16px;
			@media(min-width: 767px){
				display: none;
			}
		}
	}

}
