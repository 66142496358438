.custom-select-wrapper {
  cursor: pointer;
  display: inline-block;
  color: #000;
  position: relative;

  &.full-width {
    width: 100%;
  }
}

.styled-custom-select-option {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: white;
  display: flex;
  align-items: center;
  height: 48px;
  padding: 0 12px;
  min-width: 15rem;
  font-family: $default-font;

  &::after {
    // font-family: 'Font Awesome 4 Free';
    // content: '\f078'; // FontAwesome chevron-down
    // font-weight: 900;
    // position: absolute;
    // top: 50%;
    // transform: translateY(-50%);
    // right: 8px;
    // line-height: 1em;
  }
}

.custom-select-options {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 5;
  margin: 0;
  padding: 0;
  list-style: none;
  font-family: $default-font;
  background-color: #fff;
  min-width: 15rem;
  box-shadow: 0 30px 20px 0px rgba(0, 0, 0, 0.05);
  user-select: none;
  
  .state-selector-wrapper & {
    max-height: 400px;
    overflow-y: scroll;
  }

  li {
    margin: 0;
    padding: 8px 8px;
    margin: 8px 8px;

    &:hover {
      background-color: #CCE7FF;
    }
  }
}