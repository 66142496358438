#artifacts {
  
  header.container-fluid {
    @include single-row-header;
    @include banner;
    @include banner-electric-blue;
  }
  .artifact-preview{
    cursor: pointer;
    margin-bottom: $space-lg;
    audio{
      width: 100%;
    }

    &.newLayout{
      background-color: transparent;
      border: 0;
    }
  }

  .col-lg-3{
    .artifact-link{
      padding: 0;
    }
  }

  .modal-title{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .sidebar {
    margin-top: 40px;
    padding: 20px;
    background-color: $light-gray;
    h2 {
      color: $cadmium-orange;
    }
  }
  .modal-dialog{
    top: -150px;
  }
  .modal-body{
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    img{
      max-width:100%;
      max-height: 100%;
    }
  }

  .tags{
    margin-bottom: $space-lg;
    a{
      font-size: 16px;
    }
  }
  h3{
    font-size: 24px;
  }
  @media(min-width: 544px){
    .modal-dialog{
      width: 750px;
    }
  }
}

.preview-label{
  background-color: #868a92; 
  padding: 8px; 
  color: white; 
  text-align:center;
  // box-shadow: 0px 0px 4px 0px rgba(0,0,0,.6);
}

.artifact-embed{
  padding-bottom: 100%;
  margin-top: 0;
  width: 100%;
  overflow: hidden;
  box-shadow: 5px 5px 10px 0 rgba(0,0,0,.2);

  // iframe{
  //   -ms-zoom: 0.75;
  //   -moz-transform: scale(0.75);
  //   -moz-transform-origin: 0 0;
  //   -o-transform: scale(0.75);
  //   -o-transform-origin: 0 0;
  //   -webkit-transform: scale(0.75);
  //   -webkit-transform-origin: 0 0;
  // }
}

.artifact-video{
  padding-bottom: 60%;
  margin-top: 0;
  width: 100%;
}
