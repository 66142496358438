.filter-controls {
	margin: $space-sm 0;
	.filter-triggers {
		@include list-no-style;
		display: flex;
		justify-content: space-between;
		width: 100%;
		position: relative;
		@media(max-width:550px){
			display: inline-block;
		}
		&.menu-is-open {
			.filter-triggers__toggle {
				border-bottom: none;
				.filter-name {
					color: $platinum;
					&::after {
						border: none;
					}
				}
				&.active .filter-name {
					color: $true-blue;
					&::after {
						@include triangle($direction: 'top', $color: $true-blue, $size: 8px);
					}
				}
			}
		}
		.filter-triggers__toggle {
			cursor: pointer;
			position: relative;
			color: $true-blue;
			border-bottom: 1px solid $true-blue;
			padding-bottom: $space-sm;
			margin-left: $space-lg;
			@media(max-width:550px){
				display: flex;
				justify-content: center;
				margin-left: 0;
			}
			.filter-name {
				position: relative;
				padding-right: 24px;
				&::after {
					position: absolute;
					right: 0;
					top: $space-sm;
					@include triangle($direction: 'bottom', $color: $true-blue, $size: 8px);
				}
			}
		}
		ul.filter-menu {
			@include list-no-style;
			display: none;
			position: absolute;
			width: 100%;
			top: 100%;
			left: 0;
			z-index: 2;
			border-top: 1px solid $true-blue;
			background-color: $light-cyan;
			padding: 0;
			&::before,
			&::after {
				content: '';
				position: absolute;
				top: 0;
				width: 100%;
				height: 100%;
				background-color: $light-cyan;
			}
			&::before {
				left: -100%;
			}
			&::after {
				left: 100%;
			}
			&.filter-menu--open {
				display: flex;
				padding: $space-lg 0;
				flex-wrap: wrap;
			}
			li.filter-trigger {
				cursor: pointer;
				font-size: 16px;
				height: 32px;
				margin: $space-sm 0;
				padding: 0 $space-md;
				margin-right: 24px;
				white-space: nowrap;
			}
		}
	}
}

.filter-display-row {
	padding: $space-sm 0;
	padding-bottom: $space-md;
	display: flex;
	align-items: center;
	justify-content: space-between;

	@media(max-width:767px){
			flex-direction: column;
	}
	
	p {
		margin: 0;
		color: $true-blue;
	}

	.selections {
		@include flex-center;
		@media(max-width:550px){
			flex-direction: column;
		}
		.selections__container {
			@include flex-center;
			@media(max-width:550px){
				flex-direction: column;
				display: block;
			}
			.selection {
				@include flex-center;
				background-color: #eff8fa;
				padding: 4px 8px;
				cursor: pointer;
				&.clear-filters{
					background-color: $taupe-gray;
					p{
						color:$white !important;
					}
					i{
						color:$white !important;
					}
				}
				p.selection__label {
					color: $taupe-gray;
					text-transform: capitalize;
					font-size: 14px;
					margin-left: $space-xs;
				}
				&[data-criterion='state'] p.selection__label {
					text-transform: uppercase;
				}
				margin: 0 $space-sm;
				margin-left: 0;
				@media(max-width:767px){
					// margin: 0;
					margin-bottom: 8px;
					display: inline-flex;
				}
				.material-icons {
					cursor: pointer;
					font-size: 1rem;
					color: $taupe-gray;
					background-color: transparent;
					border-radius: 50%;
				}
			}
		}
	}
}

ul.layout-switch-menu {
	@include list-no-style;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin: $space-md 0;
	li.layout-switch {
		cursor: pointer;
		margin-left: $space-md;
		&.highlighted {
			color: $true-blue;
			font-weight: 700;
			border-bottom: 2px solid $true-blue;
		}
	}
}
