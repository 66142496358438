// mixins

@mixin banner {
	position: relative;
	min-height: 215px;

	&:before {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		height: 100%;
	}
}

@mixin banner-verdigris {
	background-image: url('https://s3-us-west-1.amazonaws.com/tla-craft-assets/images/static/noise_verdigris.jpg');

	&:before {
		background-image: linear-gradient(
			45deg,
			rgba(145, 250, 236, 1) 0%,
			rgba(70, 191, 177, 1) 35%,
			rgba(70, 191, 177, 1) 65%,
			rgba(145, 250, 236, 1) 100%
		);
		opacity: 0.4;
	}
}

@mixin banner-electric-blue {
	background-image: url('https://s3-us-west-1.amazonaws.com/tla-craft-assets/images/static/noise_electric_blue.jpg');

	&:before {
		background-image: linear-gradient(
			45deg,
			rgba(23, 177, 212, 1) 0%,
			rgba(0, 80, 139, 1) 35%,
			rgba(0, 80, 139, 1) 65%,
			rgba(20, 174, 209, 1) 100%
		);
		opacity: .5;
	}
}

@mixin banner-cadmium-orange {
	background: url('https://s3-us-west-1.amazonaws.com/tla-craft-assets/images/static/noise_orange.jpg');

	&:before {
		background-image: linear-gradient(
			45deg,
			rgba(250, 201, 158, 1) 0%,
			rgba(239, 130, 34, 1) 35%,
			rgba(239, 130, 34, 1) 65%,
			rgba(250, 201, 158, 1) 100%
		);
		opacity: .5;
	}
}

@mixin banner-mantis {
	background-image: url('https://s3-us-west-1.amazonaws.com/tla-craft-assets/images/static/noise_mantis_green.jpg');
}

.banner {
	h1 {
		color: $white;
		font-weight: lighter;
		font-size: 60px;
	}
	&.banner--text-centered {
		@include vertical-center;
		text-align: center;
		h1 {
			color: $white;
		}
	}
	&.banner--blue {
		@include banner-electric-blue;
	}
	&.banner--verdigris {
		@include banner-verdigris;
	}
	&.banner--mantis {
		@include banner-mantis;
	}
	&.banner--bg-cover {
		@include bg-cover;
	}
}
@media (max-width: 992px){
	.banner {
		height: auto !important;
		h1 {
			font-size: 40px;
			margin-top: 40px;
			margin-bottom: 40px;
		}
	}
}