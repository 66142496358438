//font size

h1 {
	font-size: 3.5rem;
}
h2 {
	font-size: 2.5rem;
}
h3 {
	font-size: 3rem;
}
h4 {
	font-size: 2.5rem;
}
h5 {
	font-size: 2rem;
}
h6 {
	font-size: 1.5rem;
}
p {
	font-size: 1.2rem;
}

@media (max-width: 768px) {
	h1 {
		font-size: 3rem;
	}
	h2 {
		font-size: 2.5rem;
	}
	h3 {
		font-size: 2rem;
	}
	h4 {
		font-size: 1.5rem;
	}
	h5 {
		font-size: 1rem;
	}
	p {
		font-size: 1rem;
	}
}
