#search {
	min-height: 1200px;

	header {
		@include banner;
		@include banner-electric-blue;
		padding: 0;
		width: 100%;
		min-height: 100px;
		padding: $space-lg 0;
		h1 {
			@include page-title;
		}

		.search-form {
			width: 100%;
			display: flex;
			padding: auto 20px;
			img {
				display: inline-block;
				height: 36px;
				width: 36px;
			}
			.query-input {
				@include search-input;
				font-size: 36px;
				width: 70%;
				line-height: 38px;
				font-weight: 300;
				margin: 0;
				padding: 8px;
				border-top-left-radius: 0.25rem;
				border-bottom-left-radius: 0.25rem;
				background-color: rgba(0, 0, 0, 0.1);
				@media (min-width: 48em) {
					width: 85%;
				}
			}
			.btn-search {
				background-color: $true-blue;
				width: 30%;
				line-height: 20px;
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
				@media (min-width: 48em) {
					width: 14%;
				}
			}
		}

		.apply-filtered-search {
			display: none;
		}
	}

	.result-row {
		@include preview-xl;
		min-height: initial;
		h3{
			a{
				font-size: 30px;

			}
		}
		.preview-content{
			span{
				font-size: 16px;
			}
		}
		.preview-image{
			// a{
			// 	display: block;
			// 	overflow: hidden;
			// 	height: 100%;
			// 	width: 100%;
			// 	min-height: 161px;
			// }
			// .bg-image{
			// 	width: 100%;
			// 	height: 100%;
			// 	border-radius: 100%;
			// 	background-size: cover;
			// 	background-position: center;
			// }
			img{
				margin-bottom: 0;
			}
		}
	}

	.sidebar-content {
		display: none;
		margin-top: 10px;
		width: 100%;
		padding: 10px;
		background: white;

		h3 {
			color: $electric-blue;
			font-weight: bold;
			font-size: 18px;
		}

		h4 {
			margin-top: 20px;
			text-transform: uppercase;
			color: $taupe-gray;
			font-size: 16px;
			line-height: 32px;
		}

		ul {
			padding: 0;
		}

		.topic-filter {
			background: $glitter;

			.panel-heading,
			.panel-title {
				margin: 0;
			}
			.panel-body ul {
				margin: 0;
			}

			.topic-select {
				@include filter-sm;
				display: block;
				width: 100%;
				text-decoration: none;
			}

			a.topic-select {
				font-size: 18px;
				line-height: 22px;
				text-transform: uppercase;
			}

			a.topic-select.selected {
				font-weight: 700;
			}

			li.topic-select {
				padding-left: 2em;
			}

			li.topic-select.selected {
				@include selected-filter;
			}
		}

		.artifact-filter {
			display: none;
			background-color: $light-gray;
		}

		.refine-by {
			border-top: 1px solid $light-gray;
		}

		li.section-select,
		li.artifact-select,
		li.school-type-select,
		li.grade-select {
			@include filter-sm;
			padding-left: 2em;
			&::after {
				@include radio-button-circle;
			}
		}

		li.section-select.selected,
		li.artifact-select.selected,
		li.school-type-select.selected,
		li.grade-select.selected {
			@include selected-filter;
		}
	}

	.label-container {
		.label-filter {
			font-size: 20px;
			line-height: 28px;
			border-radius: 10px;
			margin-top: 10px;
			margin-right: 10px;
			color: $electric-blue;
			background-color: $bubbles;

			a.dismiss {
				color: $electric-blue;
				text-decoration: none;
			}
		}
	}

	.search-display {
		min-height: 800px;
	}

	.practices-site-results {
		margin: $space-lg 0;
		background-color: $cement;
		padding: $space-lg;
		h4 {
			font-size: 24px;
			margin-bottom: 12px;
			color: $electric-blue;
		}
		.result {
			margin: $space-md 0;
			color: $taupe-gray;
			text-transform: uppercase;
			
			h5 {
				@include link-style($taupe-gray);
				font-size: 22px;
				color: $electric-blue;
				font-weight: 300;
				text-transform: initial;
			}
			p{
				overflow: hidden;
				height: 45px;
				font-weight: 300;
				font-size: 15px;
				color: $taupe-gray;
			}
		}
	}
}
