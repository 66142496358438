#insights {
	header.container-fluid {
		@include single-row-header;
		@include banner;
		@include banner-electric-blue;

		.header-text {
			padding-top: 40px;
			h4{
				font-size: 1.75rem;
				color:white;
			}
		}

		.author {
			display: flex;
			align-items: center;

			.author-image {
				width: 100px;
			}

			.author-info {
				h3,
				i {
					display: block;
					color: white;
				}
				@media (min-width: 48em) {
					padding-left: 20px;
				}
			}
		}
	}

	.tout{
		.wrapper{
			padding: 24px;
			background-color: $white;
			box-shadow: 0 5px 2px 0 rgba(0,0,0,0.18);
			h4{
				text-transform: uppercase;
				color: $electric-blue;
				font-size: 24px;
			}
			p{
				color: $electric-blue;
				margin-bottom: $space-lg;
			}
			a{
				display: block;
				padding: 12px;
				color: $white;
				font-weight: bold;
				background-color: #6FC03A;
				box-shadow: 0 3px 0 0 #00548B;
				text-decoration: none;
				position: relative;
				i{
					position: absolute;
					right: 32px;
					top: 16px;
				}
			}
			@media(min-width: 767px){
				margin-top: -45px;

			}
		}
	}

	.filter-display-row{
		padding: 16px;
	}

	.filter-trigger{
		a{
			color: $electric-blue;
			text-decoration: none;
		}
	}

	.page-content {
		aside {
			margin: 0 5px 5px 10px;
			width: 100%;

			@media (min-width: 34em) {
			}

			h3 {
				color: $cadmium-orange;
			}

			img {
				max-width: 100%;
				margin-bottom: 10px;
			}

			.mention {
				width: 100%;
				margin-bottom: 10px;
				padding-top: 10px;
				padding-bottom: 10px;
				div {
					width: 50%;
					float: left;
				}

				.strategy-preview,
				.nonfeatured-artifact {
					margin: 15px auto;

					.preview-icon {
						width: 25%;
					}
					.preview-content {
						width: 70%;
						h3 {
							font-size: 13px;
							text-transform: uppercase;
						}
					}
				}
			}
		}
	}

	.editorial-row,
	.author-bio {
		@include preview-xl;
	}

	@media (max-width: 34em) {
		.editorial-row .preview-image {
			flex-direction: row;
			width: 100%;
			justify-content: space-between;

			.author-info {
				text-align: right;
			}

			img {
				width: 30%;
			}
		}
	}

	.author-bio {
		border-top: 1px solid $light-gray;
		border-bottom: 1px solid $light-gray;
		padding-top: 20px;
		padding-bottom: 20px;

		@media (min-width: 34em) {
			width: 66%;

			.author-info {
				text-align: left;
			}
		}

		p {
			margin: 0;
			text-align: left;
		}
	}

	.author-info {
		text-align: center;

		i {
			display: block;
			margin-top: 5px;
			margin-bottom: 10px;
			font-size: 15px;
			line-height: 17px;
		}
	}

	@media (max-width: 992px){
		.page-content {
			aside {	
				.mention {
					div {
						width: 100% !important;
						.preview-icon{
							width: 20% !important;
							margin-right: 10px !important;
							display: flex;
							align-items: center;
						}
						.preview-content{
							a{
								font-size: 13px;
							}
						}
					}
				}
			}
		}
	}
}
